@import "../../scss/lib.module";

.root {
  display: flex;
  align-items: normal;
  flex: 1 1 auto;

  @media(max-width: 414px) {
    flex-direction: column;
  }
}

.aside {
  flex: 0 0 40rem;
  max-width: 40rem;
  background-color: $background-color-aside;

  @media(max-width: 414px) {
    max-width: unset;
  }
}

.details {
  flex: 1 1 auto;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 20px;
}

.padded {
  padding: 3.2rem 4rem;
}

.actionBar {
  margin: 1rem 0;
}
svg.actionIcon {
  &.actionIcon {
    margin-left: 0;
  }
}

.actionButton {
  font-size: 1.1rem;

  padding: 0.4rem 0.4rem;

  > span {
    display: flex;
    align-items: center;
  }
}