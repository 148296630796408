@import "../../../scss/lib.module";

.fieldset {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  border: 0;
  padding: 0;
}

.fieldset + .fieldset {
  margin-top: 2rem;
}

.fieldsetTitle {
  margin: 1rem 0;
}
