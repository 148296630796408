@import "../../../scss/lib.module";

.secondaryActionsWrapper {
  display: flex;
  justify-content: space-between;
}
.secondaryActions {
  display: flex;
  align-items: center;
  .item {
    font-weight: normal;
    position: relative;
    &:not(:last-child) {
      margin-right: 2rem;
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 1rem;
        background: $color-light-gray;
        position: absolute;
        top: 50%;
        left: calc(100% + 1rem);
        transform: translateY(-50%);
      }
    }
  }
}
