@import "../../../scss/lib.module";

.agentTierBadge {
  circle {
    display: none;
  }

  text {
    fill: white;
  }
}

.tier1 {
  background: $gradient-tier-1;
}

.tier2 {
  background: $gradient-tier-2;
}

.tier3 {
  background: $gradient-tier-3;
}

.elite {
  background: $gradient-elite;
}
