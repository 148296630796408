@import "../../../scss/lib.module";

div.section {
  margin-bottom: 0;
  width: 80rem;
}

button.filter {
  width: 100%;
  text-align: left;
}

.hasAutocomplete {
  overflow: unset;
}
