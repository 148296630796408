@import "../../../scss/lib.module";

.agentPrograms {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1;
}
.agentProgram {
  display: inline;
  margin-right: 0.2rem;
}

.programCounter {
  margin-top: 0.4rem;
  color: $title-font-color;
  text-align: center;
}
