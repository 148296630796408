@import "../../../scss/lib.module";

.radioTabs {
  display: flex;
  width: 100%;
}

.radioTab {
  align-items: center;
  border: 1px solid $color-light-gray;
  border-left-width: 0px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 1.6rem;
  font-weight: 300;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  transition: all .2s ease-out;
  
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid $color-light-gray;
  }
  
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.checked {
    border: 1px solid $color-electric-blue;
    background-color: $color-warm-light-gray;
    font-weight: bold;
  }
}