@import "../../scss/lib.module";

.section {
  flex: 1 1 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

.section + .section {
  margin-top: 2rem;
}

.sections {
  > .section + .section {
    margin-top: 0;
  }
}

.sectionsWrapper + .sectionsWrapper {
  margin-top: 2rem;
}

.section + .sectionsWrapper {
  margin-top: 2rem;
}

.sectionsWrapper + .section {
  margin-top: 2rem;
}

.sectionsWrapper {
  width: 100%;
  flex: 0 0 auto;
}

.sections {
  display: flex;
  width: 100%;

  > .section {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  & > .section:first-child {
    margin-left: 0;
  }
  & > .section:last-child {
    margin-right: 0;
  }

  @media(max-width: 414px) {
    flex-direction: column;

    & > .section:first-child {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }
    & > .section:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.contents {
  flex: 1 1 100%;
  height: 100%;
  white-space: pre-line;
  & > .section {
    margin-left: 0;
  }
  & > .section + .section {
    margin-top: 2rem;
  }
}
