@import "../../../scss/variables/_index";

.input {
  position: relative;
  width: 16px;
  height: 16px;
  box-sizing: border-box;

  appearance: none;
  border-radius: 100%;
  background-color: $color-very-light-gray;
  border: 1px solid $color-dark-gray;

  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    background: $color-electric-blue;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 250ms;
  }

  &:checked {
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &:disabled {
    border: 1px solid $color-light-gray;
    cursor: not-allowed;
  }
}

.radioButtonRowParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}
.radioButtonRow {
  max-width: 50px;
  margin-top: 0 !important;
}


