@import "../../../scss/lib.module";

.title {
  font-size: $title-font-size;
  font-weight: bold;
  margin: 5px 0;
  flex: 1 1 auto;
}

.subTitle {
  font-size: 1.6rem;
  color: $body-secondary-text-color;
  margin: 1rem 0;
}
