@import "../../scss/lib.module";
@import "../../components/Button/Button.module.scss";

.root {
  display: flex;
  align-items: center;
  position: relative;
  & > * {
    flex: 1 1 auto;
  }
  svg,
  span {
    flex: 0 0 auto;
  }
}

.title {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  border-radius: $input-button-border-radius;
  align-items: center;
  :global(.downChevron),
  :global(.upChevron) {
    height: 1.4em;
    width: 1.4em;
    margin-left: 0.15em;
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.section {
  padding: 0;
}

.section + .section {
  margin-top: 2rem;
}

.sectionTitle {
  color: $color-dark-gray;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0.5rem 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $site-separator-color;
  display: flex;
  align-items: center;
}

.item {
  padding: 0 0.5rem;
  line-height: 1.4;
  white-space: nowrap;
  a {
    @extend button, .menuButton;
  }
  &:global(.selected) button {
    font-weight: bold;
    color: $color-pitch-black;
  }
}

.menuOpen {
  path {
    fill: $coolGray1;
  }
}
