@import "../../scss/lib.module";

.loButton {
    background-color: $color-white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .loButtonActive {
    background-color: $color-electric-blue;
    color: $color-white;
  }