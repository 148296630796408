@import "../../scss/lib.module";

.form {
  display: flex;
  flex-direction: column;
  input,
  textarea,
  select {
    &:focus {
      outline-offset: -3px;
      outline-width: 1px;
      outline-color: $color-electric-blue;
    }
  }
  .checkBox,
  .radio {
    margin: 1px 3px;
    flex: 0 0 auto;
  }
  .inlineMode {
    align-items: normal;
  }
  .field {
    &.inline {
      padding-top: 1.5rem;
    }
  }
  .label {
    margin-bottom: 0.5rem;
  }
}

.title {
  margin: 1rem 0;
}
input,
textarea,
select {
  background: none;
}
input,
textarea,
select,
.input {
  border-radius: $input-button-border-radius;
  border: solid 1px $input-border-color;
  padding: $input-padding;
  ::placeholder {
    color: $input-placeholder-color;
  }
}

input[readonly] {
  background-color: $color-smoke;
  cursor: not-allowed;
}

.textArea {
  width: 100%;
  resize: vertical;
  height: 12rem;
}

.textInput {
  width: 100%;
}

.hasError {
  border-color: $color-alert-red;
}

.select {
  width: 100%;
  padding-right: 3rem;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:not(.filled) {
    color: $color-dark-gray;
  }
}

.selectWrapper {
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -1.2rem;
    height: 2.4rem;
    width: 2.4rem;
    color: $color-electric-blue;
    pointer-events: none;
  }
  & + & {
    margin-top: 0.6rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: right;

  button {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }

  button:last-child {
    margin-right: 0;
  }

  .confirmation {
    margin-right: 1rem;
    color: $color-electric-green;
  }
  .error {
    margin-right: 1rem;
    color: $color-alert-red;
  }
}

.field {
  color: $form-text-color;
  display: block;
  flex: 1 1 100%;
  font-size: 1.2rem;
  line-height: normal;
  position: relative;
  text-overflow: ellipsis;
  min-height: 2.3rem;
  @include ie_only {
    min-height: 60px;
  }

  &.inline {
    display: flex;
    .label {
      display: inline-block;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

  &.error {
    &:focus-within {
      .hint.show {
        display: none;
      }
    }

    &:not(:focus-within) {
      input {
        border-color: $color-alert-red;
      }
    }
  }

  .label {
    display: block;
    font-weight: 400;
    color: $title-font-color;
    font-size: 1.4rem;
  }
  .displayValue {
    flex: 0 0 58%;
    min-width: 0;
    max-width: 100%;
    font-weight: 700;
    min-height: 2.3rem;
    display: flex;
    align-items: center;
  }
  .required {
    color: $color-ruby-red;
    margin-left: 0.2rem;
  }

  .hint {
    display: block;
    font-size: x-small;
    margin-top: 0.5rem;

    &.show {
      opacity: 1;
    }

    &.errorMsg {
      color: $color-alert-red;
    }
  }

  .note {
    font-style: italic;
    margin-left: 0.5rem;
    color: $color-gray;
  }
}

.field + .field {
  margin-top: 2rem;
}

.inlineMode + .inlineMode {
  margin-top: 0.5rem;
}

.inlineMode {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 auto;
  }
  & > *:first-child {
    margin-right: auto;
  }
  .label {
    flex: 0 0 39%;
    margin-right: 0.5rem;
    min-height: 2.3rem;
    display: flex;
    align-items: center;
  }
  .hint {
    flex: 0 0 100%;
    text-align: right;
    padding-right: 1.4rem;
  }
}

.inlineMode {
  &.checkBoxField {
    flex-wrap: nowrap;
    align-items: center;

    .displayValue {
      flex: 0 0 auto;
      margin-right: 0;
      min-height: 0;
    }

    .label {
      font-weight: 300;
      margin-left: 0;
      padding-left: 0.5rem;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &.checkBoxFieldNoLabel {
    flex: unset;
  }

  .checkBox {
    min-height: 2.3rem;
  }
}

.noWrap {
  flex-wrap: nowrap;
}

.passwordInputWrapper {
  position: relative;

  svg {
    color: $color-pitch-black;
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    right: 1rem;
    top: -0.25rem;
    transition: opacity 0.2s ease-out;
    width: 1.5rem;

    &:hover {
      opacity: 1;
    }
  }
}

.value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    width: 100%;
  }
}
