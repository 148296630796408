@import "../../scss/lib.module";

@media (max-width: 960px) {
  .pageWrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .consoleAside {
    width: 100%;
    max-width: unset;

    @media (max-width: 960px) {
      flex: auto;
    }
  }
}

.aside {
  padding: 3.2rem 4rem 5rem;

  @media (max-width: 960px) {
    padding: 1rem 5rem 1rem 2rem;
  }
}

.details {
  padding: 0 4rem 5rem;

  @media (max-width: 960px) {
    padding: 0 5.5rem 0 2rem;
  }
}

.tabs {
  background: $color-white;
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    overflow-x: scroll;
  }
}

.tabsWrapper {
  display: flex;

  @media (max-width: 414px) {
    flex-direction: column
  }
}

.tabsButtons {
  align-self: center;

  & > button:last-child {
    margin-left: 1.2rem;
  }
}

.inspect {
  max-width: 800px;
}

.leadActions {
  display: flex !important;
  flex-direction: row-reverse !important;
}
