@import "../../scss/lib.module";

.hiddenWrapper {
  max-height: 26rem;
  overflow: hidden;
  position: relative;
  padding-top: 4.8rem;
  border: solid 2px #dbdfe6;
  display: flex;
  justify-content: space-between;
  transition: none;

  &.shown {
    max-height: 100vh;
    transition: max-height 0.3s ease-in-out;
  }

  .hiddenAgentHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 1.2rem;
    font-size: 1.4rem;
    background-color: $color-warm-light-gray;
    color: #55585e;

    svg {
      width: 2rem;
      margin-right: 0.8rem;
    }
  }

  .hiddenAgentHeaderOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .whiteAside {
    background-color: #fff;
    opacity: 0.5;
    pointer-events: none;
  }

  .profileButtonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 960px) and (max-width: 1200px) {
      flex: 1;
      align-items: center;
    }
  }
  .profileButton {
    background-color: $color-electric-blue;
    color: #fff;
    margin-right: 2.4rem;
    padding: 0 2.4rem;
    height: 4.8rem;
    line-height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    border-radius: 0.6rem;
    cursor: pointer;
  }
}
