.section {
  span {
    font-size: 16px;
  }
}

.disclaimer {
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  align-items: flex-start;
  border-radius: 6px;
  background: var(--Blue-Blue-200, #e9f6ff);
  color: var(--Blue-Blue-700, #0c6eba);

  svg {
    margin-top: 10px;
  }
}
