@import "../../../scss/lib.module";

.marketplaceProgramList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  :global(.listItem + .listItem) {
    margin-left: 0.4rem;
  }
}
