@import "scss/lib.module.scss";

.dialerContainer {
  z-index: 2;
  width: 25rem;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  backdrop-filter: blur(4px);
  bottom: 1rem;
  right: 2rem;
}

.minifiedContainer {
  display: flex;
}

.minifiedRightAction {
  padding: 1.4rem;
  border-left: 1px solid #444;
  button {
    width: 4.5rem;
    height: 4.5rem;
    margin: 0;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.minifiedLeftSection {
  border-right: 1px solid #000000;
  width: 100%;
  display: flex;
  padding: 1rem;
}

.minifiedSelectionButton {
  width: 2.4rem;
  height: 2.4rem;
  background-color: $color-dark-gray;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  svg {
    color: #fff;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.5rem;
    margin-left: 0.2rem;
  }
}

.minifedSelection {
  // width: 6.7rem;
  margin: 0.4rem;
}

.minifiedDisplay {
  width: 100%;
  text-align: left;
  margin-right: 0.5rem;
  .phoneType {
    text-align: left;
  }
}

.collapseButton {
  cursor: pointer;
  display: block;
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  &:hover {
    color: $color-light-gray;
  }
}
.closeButton {
  position: absolute;
  right: 0;
  top: -3rem;
  border-radius: 50%;
  border: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #ddd;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  svg {
    width: 16px;
    height: 16px;
  }
}

.keyPad {
  display: flex;
  flex-wrap: wrap;
}
.key {
  border: none;
  margin: 1rem 1rem;
  text-decoration: none;
  background-color: $color-dark-gray;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: background 150ms;
  border-radius: 50%;
  appearance: none;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  &:nth-of-type(10) {
    font-size: 4.2rem;
    font-weight: 400;
    padding-top: 0.1rem;
  }
  &:hover {
    background-color: $color-gray;
  }
  &:focus {
    outline: 0;
    background-color: darken($color-haze, 30%);
  }
}

.container {
  width: 18rem;
  margin: 0 auto;
}

.phoneNumber,
.phoneName {
  padding: 1rem 0 0;
  text-align: center;
  font-size: 2.2rem;
  color: #fff;
  white-space: pre;
  line-height: 1.4;
}
.phoneType {
  padding: 0.8rem 0 0;
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
  white-space: pre;
  line-height: 1.4;
}

.timer {
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
  padding: 0.8rem 0 1.5rem;
}

.callButton {
  display: block;
  border: none;
  color: white;
  border-radius: 3rem;
  width: 6rem;
  height: 6rem;
  margin: 1rem auto 2em auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call,
.endCall {
  outline: none;
  transition: background 150ms;
  cursor: pointer;
}
.call {
  background-color: $color-electric-green;
  &:hover {
    background-color: lighten($color-electric-green, 10%);
  }
  &:focus {
    background-color: darken($color-electric-green, 10%);
  }
}

.endCall {
  background-color: $color-ruby-red;
  &:hover {
    background-color: $color-alert-red;
  }
  &:focus {
    background-color: darken($color-alert-red, 30%);
  }
  svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.dialerSource {
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  white-space: pre-line;
}

.dialerDisplay {
  color: #fff;
  font-size: 1.2rem;
  margin-top: 0.3rem;
  white-space: pre;
  line-height: 1.3;
}

.padding {
  padding: 2rem 2rem 1rem;
}

.sdkVersion {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
}
