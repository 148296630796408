.pill {
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Basic-White, #fff);
}

.active {
  border: 2px solid var(--blue-blue-500-electric-blue, #46b6ff);
  cursor: pointer;
}

.inactive {
  border: 1px solid var(--Gray-Gray-300, #dbdfe6);
}
