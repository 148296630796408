@import "../../../scss/lib.module";

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search {
  width: 100%;
  background-color: white;
  margin-bottom: 1.2rem;
}
