@import "../../scss/lib.module";

div.popup {
  width: fit-content;

  .popupContent {
    min-width: 68rem;
    max-width: 80rem;
    width: 100%;
    padding: 2rem;
  }
}
