.mobileRequestValuationBtnContainer {
  width: 100%;
  padding: 16px 16px 28px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: white;
  border-top: 1px solid #eef0f6;
  margin: 0 15px;
}

.requestValuationBtn {
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  margin: 0 15px;

  @media (min-width: 960px) {
    flex: unset;
    align-self: center;
  }
}
