@import "../../../scss/lib.module";

.status {
  width: 5rem;
  text-align: center;
}

.icon {
  height: 16px;
  width: 16px;
  margin: 2px 8px;
  color: $body-icon-color;
}
.assignee {
  width: 14rem;
}

.categoryStage {
  width: 14rem;
}

.ir {
  width: 6rem;
}

.irec {
  font-weight: normal;
  font-size: 0.8em;
  opacity: 0.7;
}