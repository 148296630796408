// This is the index that will create global scss

@import "reset.global.module";
@import "lib.module";

:global(body.modal-open) {
  overflow: hidden;
}

:global(body.noscroll) {
  height: 100vh;
}

body {
  font-family: $global-font;
  color: $body-text-color;
  background-color: $color-white;
}

a {
  text-decoration: none;
  color: $body-link-color;
}
a:hover {
  text-decoration: underline;
}

.wrapNewLine {
  white-space: pre-line;
}

:global {
  #root {
    min-width: 960px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @media (max-width: 960px) {
      width: 100vw;
      min-width: unset;
    }
  }

  .nprogress-custom-parent {
    overflow: unset !important;
  }

  #nprogress .bar {
    background: $color-warning-yellow !important;
    height: 0.3rem !important;
    position: fixed !important;
    top: 0 !important;
  }

  #nprogress .peg {
    box-shadow: 0 0 1rem $color-warning-yellow, 0 0 5px #ff0303 !important;
  }

  #nprogress .spinner {
    top: 24px !important;
    left: 220px !important;
    position: fixed !important;
  }

  #nprogress .spinner-icon {
    padding: 5px;
    border: none !important;
    position: relative;
    animation: none !important;

    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      rect {
        fill: $color-electric-blue;
      }
    }
  }

  .agentAvatar {
    width: 4.4rem;
    height: 4.4rem;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
