.sectionsContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0.4rem;
}

.list {
  overflow-y: auto;
  max-height: 120px;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
}
