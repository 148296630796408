.section {
  margin-top: 30px;
  margin-bottom: 30px;

  span {
    font-size: 16px;
  }
}

.button {
  color: var(--Basic-White, #fff);
  font-weight: 700;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--blue-blue-5-electric-blue, #46b6ff);
}
