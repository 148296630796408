@import "../../scss/lib.module";

.details {
  padding: 0 4rem 5rem;
}

.aside {
  padding: 3.2rem 4rem;
}

.tabs {
  background: $color-white;
  flex: 0 0 auto;
}
