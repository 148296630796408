@import "../../../scss/variables/_index";

.checkbox {
  display: flex;
  align-items: center;
  .input {
    display: none;
  }
  .box {
    flex-shrink: 0;
    flex-grow: 1;
    min-height: auto;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $color-dark-gray;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-very-light-gray;
    &.box {
      margin: 0;
    }
    svg {
      width: 10px;
      height: 10px;
      margin-left: 1px;
      path {
        stroke: currentcolor;
        stroke-width: 2;
      }
    }
  }
}

.checkbox.checked {
  .box {
    background: $color-electric-blue;
    color: white;
  }
}

.checkbox.disabled {
  .box {
    opacity: 0.4;
  }
}
