@import "../../../scss/lib.module";

.buttonFormContents {
  padding: 2rem;
}

.optionsWrapper {
  padding: 2rem;
}

.form {
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 50rem;
    max-width: 60rem;
  }
}

.errorMessage {
  margin-bottom: 1rem;
  color: $color-alert-red;
}

div.popup {
  min-width: 40rem;
}

.sectionTitle {
  margin-bottom: 1.6rem;
}

.input {
  height: 4.8rem;
  font-size: 1.6rem;

  input { 
    height: 4.8rem;
    font-size: 1.6rem;
  }
}

.note {
  font-size: 1.25rem;
  margin: 1rem .5rem 2rem 0;
  display: inline-block;
}