div.section {
  margin-bottom: 0;
  width: 90rem;
}

:global(.resetOverflow) {
  overflow: unset;
}

.priceRangeFields input:last-of-type {
  margin-top: 0.8rem;
}
