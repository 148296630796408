.row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-self: flex-end;
  }
}
