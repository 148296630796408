@import "../../../scss/lib.module";

.message {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.aside {
  flex: 0 0 33px;
}

.main {
  flex: 1 1 auto;
  line-height: 1.2;
}

.header {
  display: flex;
  font-size: 0.9em;
  align-items: baseline;
}

svg.homeLight {
  circle {
    fill: $color-electric-blue;
    stroke: none;
  }
  text {
    fill: white;
  }
}

.name {
  flex: 0 0 auto;
  font-weight: bold;
}

.dateTime {
  flex: 0 0 auto;
  font-size: 0.8em;
  margin-left: 1rem;
  color: $color-gray;
}

.body {
  color: $color-dark-gray;
  white-space: pre-line;
  line-height: 1.4;
  font-size: 1.4rem;
}
