.root {
  display: flex;
  align-items: center;
  
  & > div {
    margin-left: 15px;
  }
  & > div:first-child {
    margin-left: 0;
  }

  @media (max-width: 414px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.search {
  flex: 1 1 auto;

  @media (max-width: 414px) {
    min-width: 95vw;
  }
}
.actions {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1.4rem;
    width: 17rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 414px) {
    margin-top: 1rem;

    &:first-child {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  
}
