@import "../../scss/lib.module";

.tabsContainer {
  display: flex;
  justify-content: space-between;
}

.tabs {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 1em;
  min-height: 6.4rem;
  border-bottom: solid 1px $site-separator-light-color;
  // overflow-x: auto;

  button:last-child {
    margin-right: 0;
  }

  a:last-child {
    margin-right: 0;
  }

  @media(max-width: 414px) {
    flex-direction: column;
  }
}

.tabs + div > .tabs,
.tabs + .tabs {
  margin-top: -1em;
  min-height: 4rem;
  font-size: 0.9em;
}

.tabs.sticky {
  position: sticky;
  top: 0;
  z-index: 4;
}

.tab {
  border: 0;
  border-radius: 0;
  color: $color-dark-gray;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 2em;
  outline: 0;
  border-top: solid 3px transparent;
  border-bottom: solid 3px transparent;
  &.selected {
    border-bottom: solid 3px $color-pitch-black;
    color: $color-pitch-black;
    font-weight: 700;
  }
  svg {
    margin-left: 0.5rem;
  }
}

.tabButton {
  @extend .tab;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}
