@import "../../scss/lib.module";

.filters {
  :global(button.menu-title) {
    font-weight: bold;
    font-size: 1.143em;
    padding: $input-padding-large;
    background-color: $background-color-aside;
  }
  :global(.menu-items) {
    background-color: $background-color-aside;
  }
}
