@import "../../../scss/lib.module";

.wrapper {
  width: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-weight: normal;
  background-color: $color-warm-light-gray;

  border-style: dashed;
  border-color: $color-gray;
  border-width: 0.2rem;
  border-radius: 0.2rem;

  transition: background 200ms ease-in-out, filter 200ms ease-in-out, color 200ms ease-in-out;

  &:hover {
    color: $color-charcoal;
    background: $color-pale-blue;
    filter: saturate(1.1) brightness(1.05);

    svg {
      transform: scale(1.3);
    }
  }

  svg {
    height: 2rem;
    margin-bottom: 0.5rem;
    transition: transform 500ms ease-in-out, filter 200ms ease-in-out, color 200ms ease-in-out;
  }

  &:focus {
    svg {
      color: $color-electric-blue;
      filter: saturate(0.9) brightness(0.95);
      transform: scale(1.4);
    }
  }
}

.filename {
  line-height: 2rem;
}

.small {
  font-size: 1.1rem;
}
