.fixed {
  position: sticky;
  top: 0;
  z-index: 3;
}

.normal {
  position: static;
}

.placeholder {
  max-width: 0;
  max-height: 0;
}
