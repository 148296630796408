@import "../../scss/lib.module";

@mixin hide-browser-default-range($trackSelector, $thumbSelector) {
  &::#{$trackSelector} {
    background-color: transparent;
    border: none;
    color: transparent;
    z-index: 1;
  }

  &::#{$thumbSelector} {
    @include appearance(none);
    background: $color-gray;
    border-radius: 0.8rem;
    cursor: pointer;
    height: 1.3rem;
    width: 0.5rem;

    &:focus {
      outline: none;
    }
  }
}

.rangeInput {
  @include appearance(none);
  @include hide-browser-default-range(-webkit-slider-runnable-track, -webkit-slider-thumb);
  @include hide-browser-default-range(-moz-range-track, -moz-range-thumb);
  @include hide-browser-default-range(-ms-track, -ms-thumb);
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  transition: all 0.2s ease-out;

  // bar styles
  &:before,
  &:after {
    background: $color-light-gray;
    content: "";
    height: 0.5rem;
    position: absolute;
    z-index: 0;
  }

  &:before {
    left: 0;
  }

  &:after {
    left: 0;
    right: 0;
  }

  &:focus {
    outline: none;
  }
}
