.default-repaper-section-header {
  margin: 0;
}

.repaper-section-header {
  padding: 0.5rem 0;
  padding-right: 1rem;
  margin-bottom: 0;
  border-bottom: none;
  background-color: #f8f8fb;
  font-size: 1rem;

  & ~ * {
    background-color: transparent;
  }
}
.extension-section-add-button,
.repaper-section-add-button {
  font-size: 1.5rem;
  color: #000;
  background: none;
  border: 1px solid #dbdfe6;
  border-radius: 0.25rem;
  &:hover {
    cursor: pointer;
  }

  &-disabled {
    @extend .repaper-section-add-button;
    color: #dbdfe6;
    cursor: not-allowed;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.extension-section-remove-button,
.repaper-section-remove-button {
  font-size: 1.5rem;
  color: #1192e5;
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
  }

  &-disabled {
    @extend .repaper-section-remove-button;
    color: #dbdfe6;
    cursor: not-allowed;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.extension-section-header {
  padding: 0.5rem 0;
  padding-right: 1rem;
  margin-bottom: 0;
  border-bottom: none;
  background-color: #f8f8fb;
  font-size: 1rem;

  & ~ * {
    background-color: transparent;
  }
}

.extension-section-add-button {
  font-size: 1.5rem;
  color: #000;
  background: none;
  border: 1px solid #dbdfe6;
  border-radius: 0.25rem;

  &:hover {
    cursor: pointer;
  }
}

.extension-section-add-button-disabled {
  font-size: 1.5rem;
  background: none;
  border: 1px solid #dbdfe6;
  border-radius: 0.25rem;
  cursor: not-allowed;
}

.extension-section-remove-button {
  font-size: 1.5rem;
  color: #1192e5;
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
  }
}

/* Contacts Section */

.contactsSection {
  display: none;

  @media (min-width: 960px) {
    display: flex;
  }
}

/* Item */

.itemContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemPopupTrigger {
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.itemPopupTitle {
  font-weight: 700;
}

/* Info */

.infoWrapper {
  min-width: 337px;
  padding: 27px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.infoValue {
  font-weight: 700;
  color: #55585e;
}

.infoIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.infoBtn {
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 4px;
  background-color: #46b6ff;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.infoLink {
  margin-top: 20px;
  font-weight: 700;
  cursor: pointer;
}

