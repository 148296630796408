@import "../../scss/lib.module";

.cardWrapper {
  flex-direction: column;
  gap: 2rem;
  border: 1px solid $color-light-gray;
  padding: 1rem 6rem 1rem 2rem;
  border-radius: $input-button-border-radius;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
}

.menuButton {
  left: 2.5rem;
}

.actionsSection {
  display: flex;
  justify-content: end;
  gap: 1rem;
  padding-bottom: 2rem;
}

.definitionStatement {
  display: flex;
  gap: 1rem;

  &:hover .deleteStatementIconContainer {
    opacity: 1;
  }
}

.deleteStatementIconContainer {
  padding: 3rem;
  margin: -3rem;
  align-self: center;
  opacity: 0;
}

.deleteStatementIcon {
  cursor: pointer;
}
