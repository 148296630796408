@import "../../../scss/lib.module";

.table {
  .name {
    width: 22rem;
  }

  .pitchStatus {
    width: 8rem;
  }

  .programs {
    width: 17rem;
  }

  .referralCounts {
    width: 10rem;
  }

  .stat {
    width: 5rem;
  }

  .date {
    width: 8rem;
  }
}

.agentNameCell:hover {
  z-index: 3;
}
