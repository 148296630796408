@import "../../../scss/lib.module";

button.rowBase {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.rowButton {
  border-color: $color-electric-blue;
  color: $color-electric-blue;
  font-size: 1.4rem;
  text-align: center;
  padding: 2px 8px;
}

button.rowInvertedButton {
  border-color: $color-white;
  color: $color-white;
  font-size: 1.4rem;
  text-align: center;
  padding: 2px 8px;
}
