.searchWrapper {
  position: relative;
  width: 100%;
  max-width: 489px;

  svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    margin-top: -1.2rem;
    color: #273653;
    height: 2.4rem;
    width: 2.4rem;
  }
}

.search {
  width: 100%;
  padding: 12px 10px;
  padding-left: 3.5rem;
  font-size: 14px;
  border-radius: 6px;

  &:focus {
    outline: #273653 1px solid;
  }
}
