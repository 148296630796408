@import "../../../../../../scss/lib.module.scss";

.fieldInformation {
  color: $color-charcoal;
  font-size: 1rem;
  font-style: italic;
  margin: 0;
  margin-bottom: 2rem;
}

.fieldDescription {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 2rem;

  strong {
    text-transform: capitalize;
  }
}

.errorValue {
  color: $alert-color;
}
