.iconsAside {
  padding: 3.2rem 4rem;

  .iconsAsideTitle {
    font-size: 2rem;
    font-weight: bold;
  }
}

.iconsSection {
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: "";
      flex: auto;
    }
  }

  .iconTitle {
    min-width: 12.5%;
    margin: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: initial;
      height: initial;
      margin: 16px 0;
    }
  }
}
