.note {
  margin-bottom: 5rem;

  .header {
    display: flex;
    align-items: baseline;
  }
  .title {
    font-weight: bold;
    font-size: 1.6rem;
  }
  .description {
    padding-left: 4rem;
  }
  .date {
    margin-left: auto;
    font-size: 1.2rem;
    color: #6d6d6d;
  }
}

.notes {
  max-width: 60rem;
}
