@import "../../../scss/lib.module";

.statusIcon {
  height: 2.2rem;
  width: 2.2rem;
  color: $body-icon-color;

  &.pastDue {
    color: $due-color;
  }
  &.dueToday {
    color: $ready-color;
  }

  &.ready {
    color: $ready-color;
  }

  &.upcoming {
    color: $upcoming-color;
  }

  &.unscheduled {
    color: $unscheduled-color;
  }
}

.details {
  width: max-content;
  max-width: 45rem;
  text-align: left;
}
