@import "../../../scss/variables/index";

.header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  :global(.block_subtitle) {
    font-size: 0.7em;
  }
}

.details {
  padding: 2rem;
  border-left: solid 1px $site-separator-color;
}

.client {
  :global(.section-title) {
    padding: 0 3rem;
  }
  li {
    padding: 0 3rem;
    margin-bottom: 1.5rem;
  }
}

.wrapped {
  text-wrap: wrap;
}