@import "../../scss/lib.module";

.body {
  white-space: pre-wrap;
  box-sizing: border-box;
  transition: max-height 0.1s ease-out;
  max-inline-size: 600px;
  word-break: break-all;
  overflow: hidden;
}

.closed > .body {
  -webkit-mask-image: linear-gradient(0deg, transparent, black 2rem);
  mask-image: linear-gradient(0deg, transparent, black 2rem);
}
