@import "../../scss/lib.module";

$color-primary: $color-electric-blue;
$tile-size: 3.3rem;

.date,
.datetime {
  :global {
    border: 0 !important;
    color: $color-charcoal;
    font-family: $global-font;
    width: 28.5rem !important;
    font-size: 1.2rem;

    abbr[title] {
      text-decoration: none !important;
      font-weight: normal;
    }

    .react-datetime-picker__inputGroup {
      display: flex;

      input {
        height: 100%;
        border-width: 1px;
        padding: 0 0.6rem;

        &:invalid {
          background: none;
        }

        &:focus {
          outline: none;
        }
      }

      &__divider {
        font-size: 1.4rem;
      }

      &__leadingZero {
        display: flex;
        align-items: center;
        margin-left: 0.4rem;
        margin-top: 0.1rem;
      }

      &__amPm {
        width: 5rem;
      }
    }

    button {
      transition: background 200ms;

      &:enabled:hover,
      &:enabled:focus {
        background: $input-hover !important;
      }
    }
  }
}

.date,
.datetime {
  :global {
    .react-calendar__navigation {
      margin-bottom: 0;
      height: $tile-size;

      .react-calendar__arrow {
        color: inherit;
      }

      .react-calendar__label {
        font-weight: 700;
        color: inherit;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      margin: 1rem 0;
    }

    .react-calendar__tile {
      color: inherit;
      padding: 0 !important;
      height: $tile-size;
      border-radius: 0.4rem;
    }

    .react-calendar__tile--now {
      font-weight: 700;
      background: none;
      border: 1px solid $color-electric-green;

      &:enabled:focus,
      &:enabled:hover {
        background: $color-vapor !important;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled,
    .react-calendar__tile--active:enabled:focus,
    .react-calendar__tile--active:enabled:hover {
      background: $color-primary !important;
      color: white;
      font-weight: 700;
    }

    .react-calendar__tile--hasActive,
    .react-calendar__tile--hasActive:enabled,
    .react-calendar__tile--hasActive:enabled:focus,
    .react-calendar__tile--hasActive:enabled:hover {
      background: $color-primary !important;
      color: white;
      font-weight: 700;
    }

    .react-calendar__month-view__days__day--neighboringMonth,
    .react-calendar__month-view__days__day--weekend {
      color: $color-gray;
    }
  }
}
