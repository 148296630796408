.pillContent {
  gap: 4px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.zipCodeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-wrap: balance;
  max-height: 300px;
  overflow-y: auto;
  min-width: 800px;
  width: 100%;
}

.divider {
  margin: 24px 0 24px;
}

.editLocationsButton {
  width: fit-content;
  display: flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--blue-blue-5-electric-blue, #46b6ff);
  color: var(--Basic-White, #fff);
  text-align: center;
  font-weight: 700;
}

.inputLabel {
  padding-bottom: 6px;
}
