@import "scss/lib.module.scss";

.table {
  th {
    width: auto;

    &.stage {
      width: 20rem;
    }
  }
}
