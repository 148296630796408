@import "../../../scss/lib.module";

.marketplaceProgramBadge {
  circle {
    display: none;
  }

  text {
    fill: white;
  }
}

.title_escrow {
  background: $color-closing-services;
}

.home_loans {
  background: $color-homeloans;
}

.simple_sale {
  background: $color-simple-sale;
}

.cash_close {
  background: $color-cash-close;
}

.referrals {
  background: $color-referrals;
}

.disclosures_io {
  background: $color-disclosures-io;
}

.trade_in {
  background-color: $color-trade-in;
}

.cash_offer {
  background-color: $color-cash-offer;
}

.hlss {
  background-color: $color-homelight-simple-sale;
}

.pending {
  opacity: 0.4;
}
