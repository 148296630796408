.button {
  padding-left: 10px;
}

.item {
  padding: 0 0.5rem;
  line-height: 1.4;
  white-space: nowrap;
  color: #666666;
  &:hover {
    color: #46b6ff;
    text-decoration: underline;
  }
}

.table {
  overflow-x: unset;
}