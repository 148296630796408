@import "../../../scss/lib.module";

.popupContent {
  padding: 2rem;
  max-width: 60rem;
}

.warningText {
  font-size: 1.4rem;
  color: $body-secondary-text-color;
}

.infoText {
  font-size: 1.2rem;
  color: $body-secondary-text-color;
}
