@import "../../../../scss/lib.module";

.buttonFormContents {
  padding: 2rem;
}

.form {
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;
}

div.popup {
  min-width: 40rem;
}