@import "../../scss/lib.module";

.danger {
  circle {
    fill: $color-alert-red;
  }
  text {
    fill: $color-white;
  }
}

.normal {
  circle {
    fill: $color-gray;
  }
  text {
    fill: $color-white;
  }
}
