.stageBadge {
  padding: 3px 6px;
  font-weight: 700;
  text-align: center;
  color: #705300;
  border-radius: 4px;
  border: 1px solid #fcf1ba;
  background-color: #fcf1ba;
}

/* ---------------------------------
 * Sizes
*/

.md {
  font-size: 14px;
}

.sm {
  font-size: 12px;
}
