.mapMenuWrapper {
  z-index: 2;
  font-size: 1.2rem;
  white-space: nowrap;
}

.mapMenu {
  width: 30rem;
  height: 23rem;
}
