.badge {
  circle {
    display: none;
  }

  text {
    font-size: 16px;
  }

  height: 22px;
  padding: 3px 5px;
}

.activeBadge {
  @extend .badge;
  text {
    fill: #3b855e;
  }
  width: 48px;
  background-color: #e8fbf0;
}

.inactiveBadge {
  @extend .badge;
  text {
    fill: #72757d;
  }
  width: 60px;
  background-color: #dbdfe6;
}

.waitingBadge {
  @extend .badge;
  text {
    fill: #705300;
  }
  width: 110px;
  background-color: #fcf1ba;
}
