@import "../../scss/lib.module";

.marked {
  h2 {
    font-size: 1.6rem;
    border-bottom: solid 1px $color-light-gray;
  }
  h3 {
    font-size: 1.4rem;
    border-bottom: solid 1px $color-light-gray;
  }
}
