.nameColumn {
  width: 20rem;
}
.roleColumn {
  width: 20rem;
}
.emailColumn {
  width: 32rem;
}
.phoneColumn {
  width: 20rem;
}
