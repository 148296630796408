@import "../../scss/lib.module.scss";

.searchFilterTag {
  border: none;
  background-color: $color-glacier-blue;
  padding: 0.3rem 0.6rem;
  margin-right: 0.25rem;
  font-size: 1.25rem;
  text-align: left;
  display: inline-flex;
  align-items: center;
  vertical-align: baseline;
  &:hover {
    background-color: rgba($color-glacier-blue, 0.6);
  }
}
.searchFilterTag + .searchFilterTag {
  margin-left: 0.4rem;
}

.closeIcon {
  width: 1.4rem;
  height: 1.4rem;
}
