@import "../../../scss/lib.module";

.lendersWrapper {
  width: 100%;
  max-width: 60vw;
}

.spinner {
  color: $body-link-color;
  width: 25px;
  height: 25px;
}

.spinnerLabel {
  color: $body-text-color;
}
