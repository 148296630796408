@import "../../scss/lib.module";

.dateInput {
  position: relative;
  width: 100%;

  input:hover {
    cursor: pointer;
  }
  input:read-only {
    background-color: $white;
  }

  > svg {
    color: $color_electric-blue;
    cursor: pointer;
    height: 1.5rem;
    margin-top: -0.75rem;
    opacity: 0.7;
    position: absolute;
    right: 1rem;
    top: 50%;
    transition: opacity 0.2s ease-out;
    width: 1.5rem;

    &:hover {
      opacity: 1;
    }
  }
  select {
    width: auto;
  }

  input {
    &:focus {
      outline-offset: initial;
      outline-width: 0;
      outline-color: transparent;
    }
  }
}
.dateCoaster {
  padding: 0.25rem;
}
.dateTimeCoaster {
  padding: 0px;
}
