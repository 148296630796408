@import "../../../scss/lib.module";

.programEligibilityLabel {
  :global(.formFieldLabel) {
    margin-right: 0.5rem;
    flex: 0 0 22%;
  }
}

.section {
  margin-bottom: 1.8rem;
}
