@import "../../scss/lib.module";

.contents {
  margin-top: 1rem;

  @media(max-width: 414px) {
    min-height: 250px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    @media(max-width: 414px) {
      flex-direction: column;
    }
  }

  .actions {
    flex: 0 0 auto;
    margin-left: auto;
  }

  .titles {
    flex: 1 1 auto;
  }

  .title {
    font-size: 2.4rem;
    font-weight: 600;

    @media (max-width: 414px) {
      font-size: 1.8rem;
      font-weight: 800;
    }
  }

  .subtitle {
    font-size: 1.2rem;
    color: $color-gray;
  }

  .contents {
    margin-top: 3rem;
    .title {
      font-size: 1.8rem;
    }
  }
}
