@import "../../../scss/lib.module";

.agentLinkWrapper {
  margin-top: 0.5rem;

  font-size: 1.2rem;
  font-weight: bold;

  line-height: 2.3rem;
}

.menu {
  display: inline-block;

  > button {
    font-size: 1.2rem;
    color: $color-electric-blue;
    font-weight: 700;
  }

  > div {
    padding-top: 0.5rem;
    min-width: 33.2rem;
    max-width: 40rem;
  }
}

.formField {
  &#{&} {
    > label {
      flex: 0 0 30%;
    }

    > div,
    > div span {
      max-width: 20rem;
      line-break: anywhere;
    }
  }
}

.content {
  min-width: 250px;
}
