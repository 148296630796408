@import "../../scss/variables/index";

.paginationContainer {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0 0 2rem;

  .page {
    display: inline-block;
    color: $color-electric-blue;
    padding: 0.5rem 1rem;
    &:active,
    &:focus {
      position: relative;
    }
    &:hover {
      background-color: $color-warm-light-gray;
    }
  }

  .page + .page {
    margin-left: -1px;
  }

  .page:last-of-type {
  }

  .page:first-of-type {
  }

  .active {
    background-color: $color-smoke;
    border-color: $color-smoke;
    color: $color-pitch-black;
    font-weight: 600;
    position: relative;
  }
}
