@import "scss/lib.module.scss";

.stats {
  font-size: 0.8em;
  line-height: 1.4;
  label {
    white-space: nowrap;
  }
  .line {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #eee;
    margin-top: 0.3rem;
  }
  .line.total {
    border-bottom: 0;
    .stat {
      font-weight: bold;
      color: black;
    }
  }
  .stat {
    color: $color-dark-gray;
  }
  .count {
    margin-left: 2em;
  }
}

.section {
  header {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    h3 {
      font-size: 1.4rem;
    }
    .menu {
      font-size: 1.2rem;
    }
  }
}
