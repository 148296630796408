@import "../../../scss/variables/index";

.disabled {
  opacity: 0.3;
  height: 16rem;
}

.aside {
  padding: 2rem 1.2rem;
}

.card {
  max-height: 82rem;
  overflow: hidden;
  border-color: $lead-stage-unstaged-color;

  > div:first-child {
    flex: 0 0 33rem;
  }

  // stages

  &.intro_request {
    border-color: $lead-stage-intro-request-color;
  }

  &.call_in_progress,
  &.client_contact,
  &.quiz_match,
  &.warm_transfer_match,
  &.client_added_match {
    border-color: $lead-stage-quiz-match-color;
  }

  &.referral_declined,
  &.failed,
  &.removed,
  &.client_unresponsive,
  &.agent_unresponsive {
    border-color: $lead-stage-referral-declined-color;
  }

  &.client_cancelled {
    border-color: $lead-stage-client-cancelled-color;
  }

  &.introduced,
  &.agent_left_vm,
  &.application_created
  &.client_left_vm {
    border-color: $lead-stage-introduced-color;
  }

  &.reassigned,
  &.awaiting_response {
    border-color: $lead-stage-awaiting-response-color;
  }

  &.connected,
  &.met_in_person,
  &.meeting_scheduled,
  &.coming_soon,
  &.listing,
  &.in_escrow,
  &.closed,
  &.closed_paid,
  &.closed_booked,
  &.client_connected,
  &.auto_intro_match {
    border-color: $lead-stage-connected-color;
  }
}
