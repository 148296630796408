@import "../../../scss/lib.module";

.wrapper {
  font-weight: normal;
  background-color: #EEF0F6;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: min-content;
  max-width: 100%;
  overflow: hidden;
  padding: 12px;
  gap: 12px;
}

.button {
  width: fit-content;
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid black;
  background: transparent;
}

.input {
  display: none;
}

.uploadedFile {
  padding: 8px 0;
  font-size: 16px;
  color: #55585E;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  overflow: hidden;

  button {
    margin-left: auto;
  }

  .icon {
    flex-shrink: 0;
  }
}
