@import "../../scss/lib.module";

.callout {
  background-color: $color-warm-light-gray;
  padding: 2rem;

  div[class^="Table_wrapper"]:last-of-type {
    padding-bottom: 0;
  }
}
