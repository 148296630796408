@import "../../scss/lib.module";

.note {
  height: 60px;
}

.form {
  min-width: 68rem;
  max-width: 80rem;
  width: 100%;
  padding: 2rem;

  &.confirmation {
    min-width: 50rem;
    max-width: 60rem;
  }

  @media(max-width: 414px) {
    min-width: 30rem;
  }
}

.errorMessage {
  margin-bottom: 1rem;
  color: $color-alert-red;
}

.nowrap {
  white-space: pre-line;
}

div.popup {
  width: fit-content;
}
