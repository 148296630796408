@import "../../../scss/lib.module";

.item {
  width: calc(33.3333% - 0.8rem);
  height: 20rem;
  margin: 0.4rem;
  border: none;
  position: relative;
  background-color: $color-warm-light-gray;
  &:hover {
    .selectIcon:not(.selected) {
      opacity: 0.95;
    }
  }
}
.link {
  width: 100%;
  height: 100%;
  display: block;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  &.selected {
    transform: scale(0.9);
  }
}
.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  > svg {
    height: 12rem;
  }
}

.selectIcon {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  cursor: pointer;
  color: white;
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  z-index: 1;
  filter: drop-shadow(1px 1px 2px rgba(39, 54, 83, 0.5));

  &.selected {
    color: $color-electric-blue;
    opacity: 1;
    background: white;
    filter: none;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.3);
  }
}
