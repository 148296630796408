.errorPage {
  text-align: center;

  header {
    h1 {
      font-size: 1.9rem;
      margin-top: 3.25rem;
      font-weight: 600;
    }

    :global(.pageSubtitle) {
      font-size: 1.4rem;
    }

    margin-bottom: 2rem;
  }
}
