@import "../../../scss/lib.module";

.table {
  .namePartner {
    width: 6rem;
  }
  .contactInfo {
    width: 8rem;
  }
  .address {
    width: 6rem;
  }
  .otherLeads {
    width: 6rem;
  }
  .price {
    width: 4rem;
  }
  .stageFurthest {
    width: 6rem;
  }
  .createdUpdated {
    width: 6rem;
  }
  .intro {
    width: 4rem;
  }
  .actions {
    width: 2rem;
    text-align: right;
  }

  :global(.highlight) {
    background: $color-warm-light-gray;
  }

  .otherLeadsLink {
    margin: 0 0.2rem;
  }
}
