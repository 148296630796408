@import "../../scss/lib.module";

.column {
  flex: 1 1 50%;
  width: max-content;
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100%;
  max-height: inherit;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.title {
  color: $color-dark-gray;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0.5rem 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $site-separator-color;
  display: flex;
  align-items: center;
}

.column + .column {
  margin-left: 1rem;
}
