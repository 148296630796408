@import "../../../scss/lib.module";

button.createButton {
  color: #fff;
  background-color: $color-midnight-blue;
  border-color: $color-midnight-blue;
  font-weight: 700;
  &:disabled {
    color: $color-gray;
  }
}
