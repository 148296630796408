
.section {
  margin-top: 30px;
  margin-bottom: 30px;

  span {
    font-size: 16px;
  }
}

.disclaimer {
    display: flex;
    margin: 16px 0px;
    padding: 6px 12px;
    align-items: center;
    border-radius: 6px;
    background: var(--Orange-Orange-100, #FFF6EB);
    gap: 6px;
    color: var(--Orange-Orange-700, #D64F00);


    svg {
        margin-top: 10px;
    }
}
