@import "../../../scss/lib.module";

.header {
  align-items: center;
  border-bottom: solid 1px $site-separator-color;
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  font-size: 1.4rem;
  font-weight: 700;
}

.borderless {
  margin-top: 1.5rem;
  border-bottom: none;
}

:global(.consoleAside) {
  .header {
    font-size: 1.2rem;
  }
}
