@import "../../../scss/lib.module";

tr.row {
  border-top: none;
}

.nameColumn,
.valueColumn,
.actionColumn {
  padding: 0.4rem 0;

  > div {
    color: $color-dark-gray;

    font-weight: 700;
  }
}

.nameColumn {
  width: 10rem;
}
.valueColumn {
  width: 17rem;
  padding-right: 2rem;
}
.actionColumn {
  width: 5.2rem;

  > button:first-child {
    margin-right: 0.5rem;
  }
}
