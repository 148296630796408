@import "../../../scss/variables/index";

.link {
  color: $color-charcoal;
  display: block;
  margin: 1rem 0;
  padding: 1rem 3rem 1rem 1rem;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
}

.link + .link {
  margin-top: 1rem;
}

.activeLink {
  background: $color-white;
  box-shadow: 0 0.2rem 0.8rem $shadow-color;
  border-radius: $container-border-radius;
}

.activeLink,
.link {
  @media (max-width: 960px) {
    :global(.block_image) svg {
      width: 3.6rem;
      height: 100%;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.actionsContent {
  display: flex;

  @media (max-width: 960px) {
    display: none;
  }
}
.relative {
  position: relative;

  @media (max-width: 960px) {
    min-width: 24rem;
  }
}
.property {
  padding: 1rem 0 0 1rem;
  font-weight: bold;
  border-left: solid 1px $color-light-gray;
  margin-left: 1rem;
  border-bottom: solid 1px $site-separator-light-color;
}

.detachButton {
  all: unset;
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.2rem;
  right: 1rem;

  &:enabled {
    cursor: pointer;
    &:hover svg {
      color: $color-electric-blue;
    }
  }
  &:disabled {
    opacity: 0;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    color: $color-light-gray;
  }
}

.order,
.ordersWrapper {
  @media (max-width: 960px) {
    overflow: scroll;
    flex-direction: row;
    display: flex;
  }
}

.leadOrders {
  @media (max-width: 960px) {
    background-color: red;
    padding-top: 0;
    margin-top: 0;
  }
}
