.creditScore {
  display: flex;
  align-items: center;
  gap: 4px;
}

.row {
  min-width: 292px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;

  a {
    justify-self: flex-end;
  }
}
