@import "../../scss/lib.module";

.searchWrapper {
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    margin-top: -1.2rem;
    color: #aaa;
    height: 2.4rem;
    width: 2.4rem;
  }
}

.search {
  width: 100%;
  padding: $input-padding-large;
  padding-left: 3.5rem;
  font-size: $input-font-size-large;
  &:focus {
    outline: black 1px solid;
  }
}
