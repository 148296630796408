@import "../../../../scss/lib.module";

.table {
  margin: 0;
}

.header {
  visibility: collapse;
  border-bottom: none;
}

.tr {
  font-size: 1.2rem;

  & > td {
    padding: 0.3rem 0.6rem;
  }
}

.program {
  width: 4rem;
}

.eligibility {
  width: 12rem;
}

.eligibilityRow {
  font-weight: 700;
}

.notEligible {
  color: $error-color;
}
