.bghover {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";

    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);

    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    border-radius: 0.5rem;
    background: transparent;
  }

  &:hover {
    &:after {
      background: #f1f1f1;
      z-index: -1;
    }

    > * {
      filter: brightness(0.95);
    }
  }
}
