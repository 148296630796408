.badge {
  circle {
    display: none;
  }

  text {
    font-size: 16px;
  }

  height: 22px;
  padding: 3px 5px;
  width: 100%;
}

.button {
  padding-left: 10px;
}

.item {
  padding: 0 0.5rem;
  line-height: 1.4;
  white-space: nowrap;
  color: #666666;
  &:hover {
    color: #46b6ff;
    text-decoration: underline;
  }
}

.table {
  overflow-x: unset;
}

.paid_row {
  font-weight: bold;
}

.activeBadge {
  @extend .badge;
  text {
    fill: #3b855e;
  }
  background-color: #e8fbf0;
}

.inactiveBadge {
  @extend .badge;
  text {
    fill: #72757d;
  }
  background-color: #dbdfe6;
}

.waitingBadge {
  @extend .badge;
  text {
    fill: #705300;
  }
  background-color: #fcf1ba;
}