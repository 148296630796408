@import "scss/lib.module.scss";

.button {
  border-radius: 0.5rem;
  border: 0;
  color: $color-dark-gray;
  font-weight: inherit;
  margin: -0.25rem -0.5rem;
  min-height: 2.8rem;
  outline: 0;
  overflow: hidden;
  padding: 0 2.4rem 0 0.5rem;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    background-color: $color-very-light-gray;
    .pencil {
      color: $color-electric-blue;
    }
  }
}

.buttonDanger {
  background-color: $invalid-input-color;
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  height: 1.4rem;
  width: 1.4rem;
}

.pencil {
  @extend .icon;
  color: $color-light-gray;
}

.spinner {
  @extend .icon;
  color: $color-electric-blue;
}
