@import "../../../scss/lib.module";

.dispositionButton {
  padding: 0.4rem 1.2rem;
  text-align: center;
  background-color: $body-link-color;
  color: $white;
  margin-left: auto;
  font-size: inherit;
  font-weight: inherit;
}

.info {
  &#{&} {
    align-items: flex-start;
  }

  &#{&} label {
    flex-basis: 16%;
  }
}

.introductionOptionsSection {
  margin-top: 2rem;
}
