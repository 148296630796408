@import "../../scss/lib.module";

.badge {
  border-radius: 5px;
  height: 2rem;
  width: 3rem;

  text {
    font-size: 1.2rem;
    dominant-baseline: central;
    font-weight: 700;
  }

  circle {
    stroke: black;
    fill: white;
  }
}
