@import "scss/lib.module.scss";

.wrapper {
  position: relative;
}

.dropdownWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;
  width: calc(100% - 2.4rem);
  border-radius: 12px;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-x: scroll;
}

.dropdownItem {
  cursor: pointer;
  width: 100%;
  padding: 8px 16px;
  background-color: white;
  transition: all .2s ease-out;
  
  &:first-child {
    border-radius: 12px 12px 0 0;
  }
  
  &:last-child {
    border-radius: 0 0 12px 12px;
  }

  &:hover {
    background-color: $color-very-light-gray;
  }
}