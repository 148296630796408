@import "../../scss/lib.module";

.divider {
  border-top: solid 1px $site-separator-color;
  text-align: center;
  font-size: 10px;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalDivider {
  position: relative;
  margin: 0 24px;
  min-width: 2px;
  max-width: 2px;
  background-color: #dbdfe6;
  display: inline-block;
}

.content {
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0.6rem;
  margin-top: -2px;
}
