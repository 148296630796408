@import "../../../scss/lib.module";

.default {
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  outline: 0;
  color: $color-electric-blue;
  background-color: transparent;
  border-color: $color-electric-blue;
  &:disabled {
    border-color: $color-light-gray;
    color: $color-light-gray;
  }
  svg {
    height: 14px;
    width: 14px;
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}

.inverted {
  color: white;
  background-color: $color-electric-blue;
  border-color: $color-electric-blue;
  &:disabled {
    color: white;
    border-color: $color-light-gray;
    background-color: $color-light-gray;
  }
}
