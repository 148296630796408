@import "scss/lib.module.scss";

button.button {
  border-radius: 0.5rem;
  border: 0;
  color: $color-dark-gray;
  font-weight: inherit;
  margin: -0.25rem -0.5rem;
  min-height: 2.8rem;
  outline: 0;
  overflow: hidden;
  padding: 0 2.4rem 0 0.5rem;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    background-color: $color-very-light-gray;
    .edit {
      color: $color-electric-blue;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    height: 1.4rem;
    width: 1.4rem;
    margin-left: 0;
    margin-right: 0;
  }

  .edit {
    @extend .icon;
    color: $color-light-gray;
  }

  .spinner {
    @extend .icon;
    color: $color-electric-blue;
  }
}

.buttonDanger {
  box-shadow: 0 0 0 1px $color-alert-red;
}

button.inlineChangeButton {
  &:hover {
    background-color: lighten($color-warning-yellow, 25);
    .edit {
      color: $color-electric-blue;
    }
  }
}
