@import "../../scss/lib.module";

.splashPage {
  z-index: 2000;
  background-color: white;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.splashContent {
  position: absolute;
  width: 100%;
  top: 25%;
  left: 0px;
}
