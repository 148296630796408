.name {
  width: 20%;
}

.email {
  width: 25%;
}

.role {
  width: 10%;
}

.enrollments {
  width: 15%;
}

.leadType {
  width: 10%;
}

.leadRouting {
  width: 10%;
}

.fallbackAgent {
  width: 10%;
}

.warmTransfer {
  width: 10%;
}

.actions {
  width: 3rem;
}

.tableWrapper {
  overflow-x: visible;
}
