@import "../../../scss/lib.module";

.input {
  display: none;
}

.button {
  white-space: nowrap;
  padding: 0.5rem 1rem;
}
