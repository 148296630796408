@import "../../scss/lib.module";

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// audio player container
.audioPlayer {
  align-items: center;
  display: flex;
}

// play/pause button
.playButton {
  border: none;
  color: $color-pitch-black;
  flex: 0 0 20px;
  outline: none;
  padding: 0;
  position: relative;
  margin-right: 0.2rem;
}

.toggle {
  animation-fill-mode: forwards;
  animation: pop-in 0.3s ease-out 0s;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

.spinner {
  color: $color-pitch-black;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s ease-out;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;

  &.on {
    animation: spin 2.5s linear 0s infinite forwards;
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.progressDisplay {
  flex: 0;
  font-size: inherit;
  margin-left: 0.5rem;
  min-width: max-content;
}

input.progressBar {
  flex: 1 0 2rem;

  &:before,
  &:after {
    background: $color-electric-blue;
  }

  &:after {
    opacity: 0.5;
  }
}
