.tr {
  td {
    vertical-align: middle !important;
  }
}

.warning {
  padding: 12px;
  background: #F5F6F9;
  color: #273653;
  display: flex;
  gap: 8px;
  font-size: 16px;

  svg {
    flex-shrink: 0;
  }
}

.dialogBodyWrapper {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
