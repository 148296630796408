.currentStage {
  margin-bottom: 16px;
  font-size: 14px;

  span {
    font-weight: 700;
  }
}

.select,
.dateInput > input {
  margin-top: 6px;
  padding: 12px 16px;
}

.select + svg,
.dateInput > svg {
  top: 27px;
}

.mobileDispositionBtnContainer {
  width: 100%;
  padding: 16px 16px 28px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: white;
  border-top: 1px solid #eef0f6;
}

.dispositionBtn {
  flex: 1;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 960px) {
    flex: unset;
    align-self: center;
  }
}
