@import "../../../scss/lib.module";

div.filter {
  padding-top: 0;
  flex-wrap: nowrap;
  & > label:first-child {
    margin-right: 1rem;
    white-space: nowrap;
    flex: 0 0 auto;
  }
}
div.filter + div.filter {
  margin-top: 0;
  margin-left: 2rem;
}

.listSelect {
  display: flex;
  flex-direction: row;
  li + li {
    margin-left: 1rem;
  }
}

button.noMap {
  white-space: nowrap;
  margin-left: 1.5rem;
  font-size: 1.2rem;
  color: $color-dark-gray;
  &:global(.selected) {
    font-weight: bold;
  }
}
