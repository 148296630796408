@import "../../scss/lib.module";

.table {
  width: 100%;
  table-layout: fixed;
  tr {
    border-top: 1px solid #eef1f7;
    &:first-of-type {
      border-top: none;
    }
  }
}
.title {
  font-weight: bold;
  padding: 0 6px;
  margin-bottom: 1rem;
}

.wrapper {
  width: 100%;
  overflow-x: auto;
  margin: 2rem 0 1rem;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: $color-very-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $color-gray;
  }
}

.tableScrollHeight {
  height: calc(90vh - 150px);
  overflow-y: auto;

  @media (max-width: 414px) {
    height: calc(70vh - 100px);
  }
}

.thead {
  border-bottom: solid 1px #dddddd;
  font-size: 1.2rem;

  tr th:first-child {
    filter: drop-shadow(0.1rem 0 #ddd);
    z-index: 3;
  }

  th {
    font-weight: bold;
  }
}

.tbody {
  font-size: 1.4rem;
}

.alternatingColumns {
  .cell:nth-child(2n) {
    background: $color-lighter-gray;
  }
}

.row {
  background: $background-color;
}

.alternatingRows {
  .row:nth-child(2n) {
    background: $color-lighter-gray;

    .cell {
      background: $color-lighter-gray;
    }
  }

}

.cell {
  padding: 10px 8px;
  vertical-align: top;
  background: $background-color;

  a {
    color: #46b6ff;
  }
}

.headerCell {
  padding: 5px 8px;
  color: #666666;
  text-align: left;
  font-weight: normal;

  button {
    font-size: 1.2rem;
    text-align: inherit;
  }
  .divider {
    margin: 0 0.2rem;
  }
}

.headerButton {
  border: 0;
  background: transparent;
  padding: 0;
  outline: 0;
  cursor: pointer;
  color: currentColor;
  font-weight: bold;
}

.selected {
  font-weight: 700;
  color: $color-disclosures-io;
}

.fixed {
  position: sticky;
  z-index: 1;
  filter: drop-shadow(0.1rem 0 $color-light-gray);
}

.fixed:first-child {
  left: 0;
}

.fixed:last-child {
  right: 0;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.actions {
  width: 6rem;
  min-width: 8rem;
  text-align: right;
}

.actionsList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button + button {
    margin-top: 0.2rem;
  }
}
.sizeXxsm {
  width: 4rem;
}
.sizeXsm {
  width: 8rem;
}

.sizeSm {
  width: 11.5rem;
}

.sizeMd {
  width: 13.7rem;
}

.sizeLg {
  width: 15rem;
}

.sizeMlg {
  width: 18rem;
}

.sizeXlg {
  width: 24rem;
}

.fixedTable {
  th {
    background-color: #f5f6f9;
    padding: 0.5rem 0.8rem;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.sortText {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .icon {
    height: 1.2rem;
    width: 1.3rem;
    visibility: hidden;
    color: $color-disclosures-io;
    &.visible {
      visibility: visible;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }
}

.menuSectionOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.5rem;
  min-width: 6.5rem;
}
