@import "../../scss/lib.module";

.invalidValue {
  background-color: $invalid-input-color;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.w100 {
  width: 100%;
}
