@import "./scss/lib.module";

@keyframes fadeIn {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.container {
  animation: fadeIn 400ms;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  margin-right: 5rem;
}
