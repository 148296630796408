@import "../../scss/lib.module";

.modal {
  align-items: center;
}
div.popup {
  height: 90vh;
  margin-bottom: 0;
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 0;
  max-width: initial;
  position: relative;
  width: 100vw;
}
.popupContent {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  overflow: auto;
  padding: 2rem;
}

.zoomedIn {
  max-width: 90vw;
  overflow: scroll;
  display: block;

  > img {
    width: unset;
    height: unset;

    max-width: unset;
    max-height: unset;

    cursor: zoom-out;
  }
}

.carouselImg {
  object-fit: fill;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  cursor: zoom-in;
}

.carouselArrow {
  background: transparent;
  border: none;
  color: $background-color;
  position: absolute;
  top: 45%;
  &:enabled {
    cursor: pointer;
  }
  svg {
    width: 6rem;
    height: 6rem;
  }
}
.left {
  left: -6rem;
  margin-bottom: 3rem;
}
.right {
  right: -6rem;
}
.popupFooter {
  flex: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-top: solid 0.1rem $site-separator-color;
  :global(.block_actions) {
    button {
      font-size: 1.4rem;
    }
  }
}
.footerTitle {
  display: flex;
  align-items: center;
  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.5rem;
  }
}
