@import "../../scss/lib.module";

svg.tier {
  border-radius: 50%;

  circle {
    fill: transparent;
  }
  text {
    fill: white;
  }
}

svg.t3 {
  background: $gradient-tier-3;
}

svg.t2 {
  background: $gradient-tier-2;
}

svg.t1 {
  background: $gradient-tier-1;
}

svg.elite {
  background: $gradient-elite;
}
