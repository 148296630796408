.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.downloadButton {
  max-width: 100%;
}
.downloadIcon {
  width: 22px;
  height: 22px;
}
.externalLinkIcon {
  width: 16px;
  height: 16px;
}
