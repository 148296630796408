.radioButtons {
  &.radioButtons label {
    cursor: pointer;
    margin: 0 0 0 0.5rem;
    line-height: 1em;
    min-height: 1.8rem;
  }

  .radioButtonField {
    min-height: 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;
  }
}
