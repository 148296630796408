@import "../../scss/lib.module";

.button {
  background: transparent;
  border-radius: $input-button-border-radius;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline;
  padding: $button-padding;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  svg:first-child {
    margin-right: 0.5rem;
  }
  svg:last-child {
    margin-left: 0.5rem;
  }

  &.error {
    border: 1px solid $error-color;
    border-color: $error-color;

    svg {
      color: $error-color;
    }
  }
}

.button:disabled {
  cursor: default;
  background-color: $color-pale-blue;
}
