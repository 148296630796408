.filterBar {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.6rem;
}

.left {
  flex: 0 0 auto;
  display: flex;
  align-items: baseline;
}

.right {
  flex: 0 0 auto;
  margin-left: auto;
  text-align: right;
}
