@import "../../scss/lib.module";

.block {
  display: flex;
  align-items: flex-start;
}

.image {
  flex: 0 0 auto;
}

.image + .text {
  margin-left: 1rem;
}

.text {
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
}

.title {
  margin-bottom: 4px;
}

.title,
.subtitle {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allowOverflow {
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
}

.subtitle,
.date {
  font-weight: normal;
  font-size: 0.8em;
  opacity: 0.7;
}

.actions {
  flex: 0 0 auto;
  margin-left: auto;
}
