@import "../../../scss/lib.module";

.primary {
  align-items: center;
  background-color: $color-electric-blue;
  border-color: $color-electric-blue;
  color: #fff;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  outline: none;

  &:hover {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: 2px solid $input-button-focus-color;
    outline-offset: 2px;
  }

  &:disabled {
    background-color: desaturate($color-electric-blue, 90%);
    border-color: desaturate($color-electric-blue, 90%);
    color: #fff;

    &:hover {
      box-shadow: none;
      filter: none;
    }
  }
}
