@import "scss/lib.module.scss";

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: -0.4rem;

  &:last-child {
    margin-right: 0;
  }
}

.agentAvatar {
  width: 4.4rem;
  height: 4.4rem;
}

.agentTierBadge {
  position: absolute;
  top: 0;
  right: -4px;
}
