@import "../../scss/lib.module";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $site-separator-color;
  padding: 10px 20px;
  flex: 0 0 20px;
}

.title {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.7rem;
  font-weight: $fontWeightRegular;
}

.close {
  flex: 0 0 2.4rem;
  color: #888;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  &:focus,
  &:active {
    outline: 0;
  }
}
