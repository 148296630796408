.dialogHeader {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
    font-weight: 700;
  }

  svg {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.contactName {
  font-size: 14px;
  font-weight: 700;
}

.contactRole {
  font-size: 14px;
  color: #8e929c;
}

.contactBtn {
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #46b6ff;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.contactBtn.disabled {
  color: #c5c8cd;
  border-color: #eef0f6;
  cursor: not-allowed;

  &:hover {
    opacity: 1;
  }
}

.noInfo {
  color: #8e929c;
}

.banner {
  margin-top: 20px;
  padding: 12px;
  display: flex;
  gap: 6px;
  color: #0c6eba;
  background-color: #f2f9fe;
  border-radius: 6px;

  p {
    flex: 1;
  }

  svg:last-child {
    justify-self: flex-end;
    cursor: pointer;
  }
}
