@import "../../scss/lib.module";

.card {
  border: solid 1px $color-light-gray;
  border-radius: 0.8rem;
  margin: 1rem 0;
  max-height: 56rem;
  .summary {
    flex: 0 0 30rem;
    overflow: auto;
  }
  .content {
    overflow: auto;
  }
}

div.summary {
  flex: 0 0 25rem;
}

div.content {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}
