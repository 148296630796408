.container {
  display: inline-block;
  position: relative;
  width: 100%;
  text-wrap: balance;
}

.menu {
  width: 100%;
  position: absolute;
  border: 1px solid var(--Gray-Gray-200, #eef0f6);
  background: var(--Basic-White, #fff);
  margin: 0.25rem 0 -0.25rem -0.5rem;
  box-shadow: 0px 4px 20px 0px rgba(108, 129, 171, 0.2);
  padding: 0 1rem 1rem 1rem;
  z-index: 100;
  text-wrap: balance;
}

.input {
  text-wrap: balance;
}
