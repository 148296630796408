@import "../../../scss/lib.module.scss";

.subSection {
  padding-left: 1rem;
  flex: 1;
}

.subSection + .subSection {
  margin-top: 2rem;
}

.subSectionHeader {
  font-size: 1.5rem;
  color: $color-gray;
}
