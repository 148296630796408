@import "../../../scss/lib.module";

.teamsList {
  :global(.listItem) {
    justify-content: flex-start;

    .teamListLink {
      margin-right: 0.5rem;
    }
  }
}
