@import "../../scss/lib.module";

.contents {
  min-width: 960px;
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;
  display: flex;
  font-size: 1.4rem;
  z-index: 0;
  padding: 0 2rem;

  @media (max-width: 960px) {
    width: 100vw;
    min-width: unset;
    padding: 0;
  }

  @media (max-width: 414px) {
    width: 100vw;
    min-width: unset;
    padding: 0;
  }
}
