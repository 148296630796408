@import "../../scss/lib.module";

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pad {
  padding: 1.5rem 3rem;

  @media (max-width: 414px) {
    padding: 1rem;
  }
}
