.menu {
  display: inline-flex;
  text-align: left;
  > button {
    flex: 0;

    :global(.UpChevron),
    :global(.DownChevron) {
      width: 16px;
      height: 16px;
    }
  }

  > div {
    width: 300px;
  }
}

.actions button {
  display: inline-block;
  width: 3.2rem;
  height: 2rem;
  line-height: 1.7rem;
  margin: 0.5rem 0.5rem 0 0;
}

.titleNoEllipsis {
  > div > div {
    overflow: unset;
    white-space: unset;
  }
}

.bghover {
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;

  &:hover {
    background: #f1f1f1;
    border-radius: 0.5rem;

    > * {
      filter: brightness(0.95);
    }
  }
}
