.autoComplete {
  :global(.autoCompleteList) {
    width: max-content;

    img {
      width: 50px;
      height: 50px;
    }
  }
}
