@import "../../scss/lib.module";

.header {
  border-bottom: solid 1px $site-separator-light-color;
  color: $title-font-color;
  flex: 0 0 auto;
  position: static;
  z-index: 1;
}

.homeLink {
  display: flex;
}

.nav {
  align-self: flex-end;
  display: flex;
  flex: 0 0 auto;
  height: 4.8rem;
  margin-bottom: 0;
  min-height: 4rem;
  transition: transform 300ms ease-in-out;

  & > * {
    display: flex;
    align-items: flex-start;
    margin-left: 3.6rem;
    border-bottom: solid 3px transparent;
  }
  > .selected {
    border-bottom-color: $body-text-color;
  }
  button {
    align-items: initial;
  }

  @media (max-width: 960px) {
    z-index: 4;
    padding-top: 2rem;
    padding-right: 5rem;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: column;

    background: white;
    box-shadow: -0.5rem 0 1.5rem #0000001f;

    transform: translateX(50rem);

    > div,
    a {
      height: 3rem;
      margin-bottom: 1rem;
    }
  }
}

.dropDownMenuItem {
  font-size: 0.9em;
}

.dropDownMenu {
  color: $color-dark-gray;
}

.navCheckbox {
  display: none;

  &:checked {
    ~ nav.nav {
      transform: translateX(0);
    }
  }
}

.navOpenIcon,
.navCloseIcon,
.navLabel {
  display: none;
  cursor: pointer;

  @media (max-width: 960px) {
    display: inline-block;
  }
}

.navCloseIcon {
  text-align: right;
  height: 3rem;
  margin-bottom: 4rem;
  cursor: pointer;
}

.responsiveMenu {
  align-items: center;
  margin-left: auto;

  display: none;

  @media (max-width: 960px) {
    display: flex;
  }

  .navLabel {
    line-height: 1rem;
  }
}

.pureCloudStatus {
  align-items: stretch;
  padding: 0 2rem;
}

.headerContents {
  padding: 0 2rem;
  width: 100%;
  display: flex;
  align-items: stretch;

  @media (max-width: 414px) {
    padding: 0;
  }

  .logoDesktop {
    padding: 1.5rem 3rem;

    @media (max-width: 414px) {
      display: none;
    }
  }

  .logoMobile {
    padding: 1rem;

    @media (min-width: 415px) {
      display: none;
    }
  }

  .links {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-left: auto;
    padding: 1.5rem 3rem;

    @media (max-width: 414px) {
      padding: 1rem;
    }

    a + a {
      margin-left: 2rem;
    }

    @media (max-width: 960px) {
      margin-left: unset;

      > div {
        margin-left: unset;
      }
    }
  }
}

.bubble {
  margin-left: 0.5rem;
  height: 2rem;
}

.canary {
  height: 2rem;
  margin-left: 3rem;
}

.hand {
  height: 2rem;
  width: 2rem;
}

.handmatch {
  text-align: center;
  display: inline-flex;
  :global(.block_subtitle) {
    font-size: 0.6em;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: $color-light-gray;
}
.normal {
  pointer-events: none;
  cursor: default;
  color: $color-electric-green;
}
.pulse {
  color: $color-electric-blue;
  animation-name: pulse;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
}

.settingsMenu {
  color: $color-gray;
  margin-left: 4rem;
  display: flex;
  align-items: flex-start;
  button {
    white-space: nowrap;
  }
}

:global(body.noscroll) .contents {
  overflow: hidden;
}

.headerBanner {
  padding: 10px 30px;
  color: $color-white;
  background: $color-ruby-red;
  text-align: center;
  transition: opacity 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
