/* Contacts Section */

.contactsSection {
  display: none;

  @media (min-width: 960px) {
    display: flex;
  }
}

/* Item */

.itemContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemPopupTrigger {
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.itemPopupTitle {
  font-weight: 700;
}

/* Info */

.infoWrapper {
  min-width: 337px;
  padding: 27px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.infoValue {
  font-weight: 700;
  color: #55585e;
}

.infoIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.infoBtn {
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 4px;
  background-color: #46b6ff;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.infoLink {
  margin-top: 20px;
  font-weight: 700;
  cursor: pointer;
}
