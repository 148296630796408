.section {
  margin-bottom: 0;
  max-width: 70vw;
}

.column {
  width: 25rem;
  display: flex;
}

.menuSection {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.checkboxArray {
  overflow: auto;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.yellow {
  background: #fff8e1;
  color: #705300;
  padding: 0px 0.5rem;
}

.orange {
  background: #ffecd1;
  color: #a83500;
  padding: 0px 0.5rem;
}

.red {
  background: #ffd4d2;
  color: #8e000e;
  padding: 0px 0.5rem;
}

.daysSinceCell {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  font-weight: 600;
}

.daysSinceField {
  width: 100%;
  font-weight: 600;
}

.sortPopover {
  display: flex;
  gap: 5px;
  align-items: center;
}

.sortPopoverOverlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.sortButton {
  font-weight: normal !important;
  text-align: start;
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
}
