@import "../../../scss/lib.module";

.item {
  font-size: 1.2rem;
  line-height: 1.5;
  padding-left: 2rem;
  position: relative;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    border-left: 1px solid $site-separator-color;
    height: 100%;
    top: 10px;
  }
  &::after {
    content: "";
    position: absolute;
    left: -0.3rem;
    top: 0.7rem;
    border-radius: 50%;
    background-color: $color-gray;
    height: 7px;
    width: 7px;
  }
  &:last-child {
    padding-bottom: 0;
    &::before {
      content: none;
    }
  }
  .icon {
    // same as gray dot above
    position: absolute;
    left: -0.8rem;
    top: 0.2rem;
    z-index: 1;

    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
    }
  }
  .main {
    flex: 1 1 auto;

    audio::-webkit-media-controls-panel {
      background: white;
      padding: 0;
    }
  }
  .aside {
    margin-left: 10px;
  }
}

.header {
  font-size: 1.4rem;
  display: flex;
  align-items: baseline;
  color: $body-text-color;
  justify-content: space-between;
  position: relative;
  margin: 0 0 0.5rem 0;
}

.title {
  font-weight: 600;
  background-color: $color-smoke;
  line-height: 1.3;
  padding: 3px 5px;
  display: inline-block;
}

.date {
  flex: 0 0 auto;
  text-align: right;
  margin-bottom: -15px;
}

.transitions {
  margin: 0.5rem 0;
  font-size: 1.4rem;
}

.audioPlayer {
  margin-top: 1rem;
}

/* Themes */
.highlight {
  &:after {
    background-color: $color-yellow;
  }
  .title {
    background-color: rgba($color-yellow, 0.2);
  }
}
.alert {
  &:after {
    background-color: $color-ruby-red;
  }
  .title {
    background-color: rgba($color-ruby-red, 0.15);
  }
}
.success {
  &:after {
    background-color: $color-electric-green;
  }
  .title {
    background-color: rgba($color-electric-green, 0.2);
  }
}

.bronze {
  &:after {
    background-color: $color-bronze;
  }
  .title {
    background-color: rgba($color-bronze, 0.3);
  }
}

.silver {
  &:after {
    background-color: $color-light-gray;
  }
  .title {
    background-color: $color-light-gray;
  }
}

.gold {
  &:after {
    background-color: $color-gold;
  }
  .title {
    background-color: rgba($color-gold, 0.3);
  }
}
