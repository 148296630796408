@import "../../../scss/lib.module";

.radioButtons {
  display: flex;
}

.warning {
  align-items: center;
  color: $color-alert-red;
  display: flex;
  margin-bottom: 1rem;

  > svg {
    margin-right: 1rem;
  }
}