@import "../../scss/lib.module.scss";

.list {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  align-items: center;
  cursor: grab;
  display: flex;
  border-radius: 0.3rem;

  background-color: transparent;

  .icon {
    min-width: 24px;
    min-height: 24px;
  }

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $color-light-gray;
  }

  &:active,
  &:focus {
    background-color: initial;
  }
}

.blocked {
  cursor: not-allowed;
  &:hover {
    background-color: transparent;
  }

  svg {
    color: $unscheduled-color;
  }
}
