div.section {
  margin-bottom: 0;
  width: 70rem;
  .checkboxField {
    margin-top: 0;
  }
}

.columnsList {
  overflow: unset;
}

.columnList {
  overflow-y: unset;
}
