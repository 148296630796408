@import "../../scss/lib.module";

.details {
  padding: 0 4rem 5rem;
}

.aside {
  padding: 3.2rem 4rem;
  .roles {
    margin-top: 2rem;
  }
}

.tabs {
  background: $color-white;
  flex: 0 0 auto;
}

.settingsList {
  li {
    min-height: 3.2rem;

    &:nth-child(2n) button {
      background-color: $color-very-light-gray;
    }
  }
}

.settingsButton {
  width: 40%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;

  &#{&}:hover {
    background-color: $color-pale-blue;
  }
}
