@import "../../../scss/lib.module.scss";

div.agentSection.agentSection {
  margin-top: 0;
  position: relative;

  &:after {
    content: "";
    height: 2rem;
    width: 0.1rem;
    background-color: $site-separator-color;
    position: absolute;
    left: 0;
    top: 0;
  }

  label {
    padding-left: 1.3rem;
  }

  &,
  label,
  span {
    min-height: 2rem;
  }
}

div[class^="Section_contents"] > div[class^="Section_section"] + .wrapper {
  margin-top: 0.5rem;
}
