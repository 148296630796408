@import "../../../scss/lib.module";

.list {
  list-style: none;
  padding: 0;
}

.divider {
  margin: 2rem 0;
}

div.newMessages {
  color: $color-electric-blue;
  border-color: lighten($color-electric-blue, 25%);
}
