@import "../../../scss/lib.module";

button.actionButton {
  font-weight: 700;
  color: $body-link-color;
  background-color: transparent;
  background-image: none;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: 0 0 5px rgba($body-link-color, 0.2);
  }
  svg {
    height: 1.6rem;
  }
}

:global(.block_actions) {
  button.actionButton {
    font-size: 1.2rem;
  }
}

button.actionButton:disabled {
  color: desaturate($body-link-color, 90%);
}
