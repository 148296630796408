@import "../../forms/Form.module.scss";

.content {
  @extend .form;
  padding-top: 1rem;
  overflow-y: hidden;
}
.currentLeadsList {
  &.currentLeadsList {
    margin: 1rem 0;
  }
  & > thead {
    visibility: collapse;
    border-bottom: none;
  }
}

.actionRow {
  & > .actionRowContent {
    display: flex;
    justify-content: flex-end;
  }
}

.attachLeadButton {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.currentBadge {
  background: $color-electric-blue;
  border-radius: 0.3rem;
  padding: 0.3rem 0.4rem;
  font-size: 1rem;
  color: white;
  font-weight: 700;
}
