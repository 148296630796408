@import "../../scss/lib.module";

.root {
  position: relative;
  width: 100%;

  .textField {
    padding-right: 2.8rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
  }

  li {
    padding: 5px;
    margin: 0;
    cursor: pointer;
    background-color: white;
    transition: background-color 100ms ease-in-out;
    &:hover {
      background-color: #eee;
    }
  }

  .hover {
    background-color: #eee;
  }

  > svg {
    color: $color_electric-blue;
    cursor: pointer;
    height: 1.5rem;
    margin-top: -0.75rem;
    opacity: 0.7;
    position: absolute;
    right: 1rem;
    top: 50%;
    transition: opacity 0.2s ease-out;
    width: 1.5rem;

    &:hover {
      opacity: 1;
    }
  }
}
