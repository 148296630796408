.radioButtons {
  display: flex;
}

.addClientHeader {
  border-bottom: none;
}

.addClientFooter {
  border-top: none;
}

.radioTab {
  font-size: 14px;
  padding: 10px 16px;
}