.checkBoxArray {
  label {
    min-height: 1.5rem !important;
    margin: 0 0 0 0.5rem;
    line-height: 1em;
  }
  .checkBox {
    min-height: 1.5rem;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 1rem;
    }

    & > label > div {
      line-height: 1.25;
    }
  }
  .checkBoxField {
    min-height: 1.5rem;
  }
}
