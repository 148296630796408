@import "../../scss/lib.module";

.details {
  padding: 0 4rem 5rem;

  @media(max-width: 414px) {
    padding: 2rem 2rem;
  }
}

.aside {
  padding: 3.2rem 4rem;

  @media(max-width: 414px) {
    padding: 3rem 2rem;
  }
}

.tabs {
  background: $color-white;
  flex: 0 0 auto;
}
