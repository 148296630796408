.handmatching {
  background-color: black;
  color: white;
  font-weight: bold;
  margin-bottom: 2rem;
  padding: 0.5rem;
  text-align: center;
}
.select {
  width: 28rem;
}

.actions {
  display: flex;
  & > * {
    margin-left: 1rem;
  }

  .approva-type-wrapper {
    width: 155px;
    margin-bottom: 5px;
  }
}
