.label {
  & > label {
    font-size: 14px !important;
    margin-bottom: 24px !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.warning {
  align-items: center;
  color: #A83500;
  background: #FFF6EB;
  display: flex;
  padding: 12px;
  gap: 6px;
  border-radius: 4px;
}

.shareExternallyWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.switchFormField {
  * {
    font-weight: 400 !important;
    color: #273653;
  }
}
