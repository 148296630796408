@import "../../../../scss/lib.module";

.incomingResidence > div {
  padding: 1rem;
  :global(.section-title) {
    font-size: 1.5rem;
    color: $color-gray;
  }
}

.irActions {
  display: flex;
  & > * {
    margin-left: 1rem;
  }
}

.select {
  border-color: $color-electric-blue;
  max-width: 12rem;
}
