@import "../../../scss/lib.module";

.agentsSection {
  min-height: 90vh;
}

.tabs {
  margin-bottom: 1rem;
  border-bottom: solid 1px #ddd;

  button {
    border: 0;
    border-radius: 0;
    color: $color-dark-gray;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    outline: 0;
    border-top: solid 3px transparent;
    border-bottom: solid 3px transparent;
    padding: 1rem;

    &:global(.selected) {
      border-bottom: solid 3px $color-pitch-black;
      color: $color-pitch-black;
      font-weight: 700;
    }

    svg {
      margin-left: 0.5rem;
    }
  }
}
