@import "../../scss/variables/index";

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
}

.list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.withMargin {
  margin-left: 1rem;
}
