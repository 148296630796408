@import "../../scss/lib.module";

.buttonFormContents {
  padding: 2rem;

  @media(max-width: 414px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.form {
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media(max-width: 414px) {
    min-width: 30rem;
  }
}

.errorMessage {
  margin-bottom: 1rem;
  color: $color-alert-red;
}

div.popup {
  width: fit-content;
}
