div.columns {
  height: 28rem;
}

div.section {
  margin-bottom: 0;
  max-width: 70vw;
}

button.filter {
  width: 100%;
  text-align: left;
}

.checkBoxArrayWithoutMargin {
  &>:first-child {
    margin-top: 0.5rem !important;
  }
}

div.info {
  width: 40rem;
}

:global(.resetOverflow) {
  overflow: unset;
}

.columns {
  overflow: visible;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.column {
  width: 23rem;
}

.tagsContainer {
  display: flex;
  gap: 4px;
  padding-top: 4px;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 250px;
  overflow: auto;
}

.tag {
  padding: 3px 0 3px 5px;
  gap: 1px;
  border-radius: 4px;
  background: #F5F6F9;
  color: #55585E;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  display: flex;
}

.button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 2px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.button:hover {
  background: rgba(85, 88, 94, 0.1);
}

.multiSelectTrigger {
  height: 32px;
}

.column {
  width: 23rem;
}

.tagsContainer {
  display: flex;
  gap: 4px;
  padding-top: 4px;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 250px;
  overflow: auto;
}

.tag {
  padding: 3px 0 3px 5px;
  gap: 1px;
  border-radius: 4px;
  background: #F5F6F9;
  color: #55585E;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  display: flex;
}

.button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 2px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.button:hover {
  background: rgba(85, 88, 94, 0.1);
}

.multiSelectTrigger {
  height: 32px;
}
