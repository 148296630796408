@import "scss/lib.module.scss";

.agentAvatars {
  display: flex;
  align-items: center;

  .avatar {
    width: 2.1rem;
    height: 2.1rem;
    border: 2px solid white;
  }

  .plusBadge {
    cursor: default;

    circle {
      fill: white;
    }

    text {
      font-size: 1rem;
    }
  }
}
