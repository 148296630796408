@import "../../scss/lib.module";

.loadingText {
  margin: 1rem 0;
}

.spinner {
  color: $color-electric-blue;
  height: 2rem;
  width: 2rem;
  vertical-align: middle;
}
