@import "../../../scss/lib.module";

.table {
  th {
    width: auto;
    &.businessUnit {
      width: 14rem;
    }
    &.product {
      width: 3rem;
    }
    &.orderStage {
      width: 8rem;
    }
    &.client {
      width: 16rem;
    }
    &.property {
      width: 20rem;
    }
    &.agent {
      width: 16rem;
    }
    &.lead {
      width: 14rem;
    }
    &.leadStage {
      width: 16rem;
    }
    &.listingStatus {
      width: 14rem;
    }
    &.transactionTeam {
      width: 10rem;
    }
    &.agentsOpps {
      width: 28rem;
    }
    &.daysOwned {
      width: 6rem;
    }
    &.date,
    &.price,
    &.gppVal {
      width: 9rem;
    }
  }

  .order {
    border-right: solid 1px $site-separator-light-color;
  }

  td.property {
    border-right: solid 1px $site-separator-light-color;
  }

  .stageIcon {
    flex: 0 0 1.8rem;
  }

  .leadAgents {
    li:not(:first-child) {
      margin-top: 5px;
    }
    .leadAgentName {
      width: 60%;
      flex: none;
      white-space: break-spaces;
    }
    .failed {
      color: $color-gray;
    }
  }

  .warning {
    color: $color-warning-yellow;
  }
  .danger {
    color: $color-ruby-red;
  }
}

.missingLeads {
  color: $color-alert-red;
}
.canary {
  vertical-align: text-bottom;
  margin-left: 0.5rem;

  &.canary {
    width: 1.7rem;
    height: 1.7rem;
  }
}
