.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 80px;

  a {
    justify-self: flex-end;
  }
}

.stage {
  display: flex;
  align-items: center;

  .box {
    cursor: default;
    width: 2.1rem;
    height: 2.1rem;
    border: 2px solid yellowgreen;
  }
}

.mt20 {
  margin-top: 20px;
}

.notes {
  overflow-wrap: anywhere;
  text-wrap: wrap;
  white-space: pre-line;
}

.notesFormField {
  flex-wrap: nowrap;
}

.multilineLine {
  padding: 4px 0;
}
