@import "../../scss/lib.module";

.searchSummary {
  align-items: flex-start;
  display: flex;
  font-size: 1.4rem;
  line-height: 2.5rem;
  margin: 1rem 0 0.3rem;
}

.clearButton,
.resetButton {
  margin-left: 0.5rem;
  line-height: 2.5rem;
}
