@import "../../scss/lib.module";

.field {
  flex: 1 1 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}

.field + .field {
  margin-top: 2rem;
}

.field.inline {
  flex-direction: row;
  header {
    flex: 0 0 39%;
  }
}

.fieldsWrapper {
  .field + .field {
    margin-top: 0;
  }
}

.fieldsWrapper + .fieldsWrapper {
  margin-top: 2rem;
}

.field + .fieldsWrapper {
  margin-top: 2rem;
}

.fieldsWrapper + .field {
  margin-top: 2rem;
}

.fieldsWrapper {
  width: 100%;
  flex: 0 0 auto;
}

.fields {
  display: flex;
  width: 100%;

  .field {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  & > .field:first-child {
    margin-left: 0;
  }
  & > .field:last-child {
    margin-right: 0;
  }
}

.contents {
  flex: 1 1 100%;
  height: 100%;
  white-space: pre-line;
}
