@import "../../scss/lib.module";

.iconTitle {
  display: inline-flex;
  align-items: center;
  width: 100%;
  svg {
    width: 1.4em;
    height: 1.4em;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
}

.iconTitle.iconFlip {
  svg {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
