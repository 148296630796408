@import "../../../../scss/lib.module";

.table {
  .otherLeadsLink {
    margin: 0 0.2rem;
  }
  .warning {
    color: $color-warning-yellow;
  }
  .danger {
    color: $color-ruby-red;
  }
  .failed {
    color: $color-gray;
  }
}
