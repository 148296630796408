@import "../../scss/lib.module";

div.wrapper {
  flex: 0 0 100%;
  overflow: hidden;
}

div.actions {
  margin: 0;
  padding: 0;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  :global(.block_subtitle) {
    font-size: 0.7em;
  }
}

.list {
  list-style: none;
}

div.nav {
  flex: 0 0 440px;
  padding: 3.2rem 4rem;
  height: 100%;
}

div.contacts {
  height: 100%;
  overflow: scroll;
}

div.details {
  padding: 3.2rem 4rem 5rem;
  flex: 1 1 auto;
  height: 100%;
  overflow: scroll;
}

.time {
  font-size: 1.2rem;
  color: $color-gray;
}

.reply {
  margin: 1rem 0;
}
