@import "~@homelight/particle-tokens/dist/rem-scaled/tokens.scss";

$color-black: #000;
$color-charcoal: #444;
$color-electric-blue: $electricBlue; // imported
$color-glacier-blue: $glacierBlue;
$color-electric-green: #38bf8f;
$color-very-light-gray: #f5f5f5;
$color-lighter-gray: #fafafa;
$color-warm-light-gray: #f5f6f9;
$color-light-gray: #ddd;
$color-gray: #888;
$color-dark-gray: #666;
$color-pale-blue: #d3e1ef;
$color-pitch-black: $darkBlue; // imported
$color-dark-blue: $darkBlue; // imported
$color-midnight-blue: #002b44;
$color-ruby-red: #bc3030;
$color-alert-red: $cadmiumRed; // imported
$color-salmon-red: #feebea;
$color-vivid-red: #ca0d02;
$color-confirmation-green: #38bf8f;
$color-warning-yellow: #fec13b;
$color-smoke: #eef1f7;
$color-vapor: #f4f4ff;
$color-light-silver: #ebebeb;
$color-haze: rgba(238, 241, 247, 0.9);
$color-dark: rgba(0, 0, 0, 0.3);
$color-vibrant-blue: #0a54d8;
$color-white: #fff;
$color-yellow: #ffc13b;
$color-bronze: #d19c75;
$color-gold: #dda605;
$color-dark-gold: #a37b03;
$color-lighter-gold: #ebb105;
$color-purple: #8e42a4;
$color-forest-green: #2e8b39;

$color-homeloans: #8e42a4;
$color-cash-close: $color-forest-green;
$color-simple-sale: $color-vibrant-blue;
$color-closing-services: black;
$color-cash-offer: #3192ba;
$color-trade-in: #45bf38;
$color-referrals: $color-gold;
$color-disclosures-io: #2f4264;
$color-homelight-simple-sale: #d06750;
$color-buyer: $color-dark-gold;
$color-seller: $color-lighter-gold;

$gradient-tier-3: linear-gradient(to right, $color-referrals, #ffcc2a);
$gradient-tier-2: linear-gradient(to right, $color-gray, $color-light-gray);
$gradient-tier-1: linear-gradient(to right, #d18e75, #ffbd9c);
$gradient-elite: linear-gradient(45deg, $color-pitch-black, $color-electric-blue);

$title-font-size: 2.4rem;

$alert-color: $color-ruby-red;
$error-color: $color-alert-red;
$invalid-input-color: #ffd8d8;
$background-color: $color-white;
$background-color-aside: $color-warm-light-gray;
$body-link-color: $color-electric-blue;
$body-icon-color: $color-charcoal;
$body-text-color: $color-pitch-black;
$body-secondary-text-color: lighten($color-pitch-black, 20%);
$disabled-color: $color-gray;
$form-text-color: $color-dark-gray;
$header-link-color: $color-dark-gray;
$header-link-hover-color: $color-electric-blue;
$header-background-color: rgba($color-pitch-black, 0.85);
$highlight-color: $color-vapor;
$modal-bg-color: $color-dark;
$primary-color: $color-vibrant-blue;
$sidebar-background-color: $color-pale-blue;
$site-background-color: $color-white;
$site-separator-color: $color-light-gray;
$site-separator-light-color: $color-smoke;
$shadow-color: $color-dark;
$soft-selection-color: $color-smoke;
$title-font-color: $color-pitch-black;

$menu-item-color: $color-dark-gray;
$menu-background-color: $color-white;

$input-border-color: $color-light-gray;
$input-placeholder-color: $color-smoke;
$input-focus-color: $color-charcoal;
$input-button-border-radius: 4px;
$input-button-focus-color: $darkBlue;

$container-border-radius: 6px;

$input-padding: 0.5rem 1rem;
$input-padding-large: 1.3rem 2rem;

$button-padding: 0.5rem 2.5rem;
$button-padding-xsmall: 0.3rem 0.6rem;
$button-padding-small: 1rem 1rem;
$button-padding-medium: 0.4rem 4rem;
$button-padding-large: 1.3rem 4rem;
$input-font-size: 1.2rem;
$input-font-size-large: 1.5rem;

$ready-color: $color-warning-yellow;
$upcoming-color: $color-forest-green;
$due-color: $color-alert-red;
$unscheduled-color: $color-light-gray;

$global-font: "Open Sans", "notoSans", "San Francisco", "-apple-system", "HelveticaNeue", helvetica,
  roboto, droid serif, arial, sans-serif;

$input-hover: $color-very-light-gray;

// lead stages
$lead-stage-unstaged-color: $color-gray;
$lead-stage-intro-request-color: #48d6d0;
$lead-stage-quiz-match-color: #0a87d8;
$lead-stage-referral-declined-color: #de4242;
$lead-stage-client-cancelled-color: #fd4ac0;
$lead-stage-introduced-color: #fb9d1f;
$lead-stage-awaiting-response-color: #983ea9;
$lead-stage-connected-color: #37ab60;

// eligibilityBadge

$tag-active-color: #0c6eba;
$tag-active-background-color: #e9f6ff;
$tag-danger-color: $color-vivid-red;
$tag-danger-background-color: $color-salmon-red;
$tag-info-color: #bb8e00;
$tag-info-background-color: #fff4d3;
$tag-warning-color: #d64f00;
$tag-warning-background-color: #ffecd1;
