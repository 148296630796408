@import "scss/lib.module";

svg.default {
  border-radius: 100%;
  vertical-align: middle;
  text {
    fill: white;
    font-size: 1.2rem;
  }
  circle {
    stroke: none;
    fill: $color-gray;
  }
}

svg.buyer {
  circle {
    fill: $color-buyer;
  }
}

svg.seller {
  circle {
    fill: $color-seller;
  }
}

svg.cc_cash_offer {
  circle {
    fill: $color-cash-offer;
  }
}

svg.escrow,
svg.title {
  circle {
    fill: $color-closing-services;
  }
}

svg.mortgage {
  circle {
    fill: $color-homeloans;
  }
}

svg.cc_trade_in {
  circle {
    fill: $color-trade-in;
  }
}

svg.cc_hl_simple_sale {
  circle {
    fill: $color-homelight-simple-sale;
  }
}
