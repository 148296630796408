.divider {
  margin: 24px 0px;
}

.dividerPayment {
  margin: 12px 0px;
}

.radio {
  padding-left: 32px;
  padding-right: 10px;

  .content {
    padding-right: 6px;
  }
}

.input {
  margin-top: 6px;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #dbdfe6;
}

.input_link {
  height: 4.8rem;
  margin-top: 6px;
  margin-bottom: 0.8rem;
  border-radius: 4px;
  border: 1px solid var(--Blue-Blue-300, #d0ecff);
  background: var(--Blue-Blue-200, #e9f6ff);
  justify-content: center;

  & input {
    border: none;
    color: var(--blue-blue-600-aa-blue, #1192e5);
  }

  > button > svg {
    width: auto;
    height: 2rem;
  }
}

.bullets {
  border-radius: 6px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  padding: 24px;
  color: #0c6eba;
  background-color: #f2f9fe;
  margin-left: 24px;
}

.list {
  padding-left: 20px;
  gap: 8px;

  li {
    margin-bottom: 8px;
  }
}

.cards {
  padding-left: 30px;

  svg {
    margin-right: 12px;
  }
}
