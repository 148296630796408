@import "../../../scss/lib.module";

.table {
  .state,
  .area,
  .asm,
  .ase,
  .agents,
  .programs {
    width: 22rem;
  }
}
