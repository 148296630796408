.listSelect {
  display: flex;
  flex-direction: row;
  li {
    flex: 0 0 auto;
  }
  li + li {
    margin-left: 1rem;
  }
}

.vertical {
  flex-direction: column;
}
