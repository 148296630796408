@import "../../../scss/variables/index";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: $lead-stage-unstaged-color;
  color: white;
}

.actionBar {
  display: flex;

  > div {
    padding: 0;
  }
}

.intro_request {
  background-color: $lead-stage-intro-request-color;
}

.call_in_progress,
.client_contact,
.quiz_match,
.warm_transfer_match,
.client_added_match,
.auto_intro_match {
  background-color: $lead-stage-quiz-match-color;
}

.referral_declined,
.failed,
.removed,
.client_unresponsive,
.agent_unresponsive {
  background-color: $lead-stage-referral-declined-color;
}

.client_cancelled {
  background-color: $lead-stage-client-cancelled-color;
}

.introduced,
.agent_left_vm,
.client_left_vm {
  background-color: $lead-stage-introduced-color;
}

.reassigned,
.awaiting_response {
  background-color: $lead-stage-awaiting-response-color;
}

.connected,
.met_in_person,
.meeting_scheduled,
.coming_soon,
.listing,
.in_escrow,
.closed,
.closed_paid,
.closed_booked,
.client_connected {
  background-color: $lead-stage-connected-color;
}
