@import "scss/lib.module.scss";

.addressAndUnit {
  display: flex;
  width: 100%;
}

.unit {
  flex: 0 0 5rem;
  min-width: 5rem;
  margin-left: 0.5rem;

  > label {
    font-weight: 400;
    color: #273653;
    position: absolute;
    top: .4rem;
    right: .7rem;
  }
}