@import "../../../scss/lib.module";

.list {
  list-style: none;
  padding: 0 0 0 8px;
  height: auto;
  audio {
    width: 100%;
    height: 25px;
    &:focus {
      outline: none;
    }
  }
}

input.search {
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.viewAll {
  margin-top: 2.5rem;
}
