.columns {
  display: flex;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.fixedHeight {
  max-height: 35rem;
  overflow-y: auto;
}
