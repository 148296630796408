@import "../../../scss/lib.module";

button.contact {
  text-align: left;
  outline: 0;
  padding: 0.8rem;
  position: relative;
  white-space: normal;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :global(.block_text) {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 6.6rem;
  }
  &::before {
    content: "";
    position: absolute;
    left: -2rem;
    top: 50%;
    margin-top: -3.5px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
  }
  &.unread::before {
    background-color: $color-electric-blue;
  }
  .header {
    display: flex;
  }
  .name {
    flex: 1 1 auto;
    font-weight: bold;
  }
  .date {
    font-size: 0.9em;
  }
  .body {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .outbound {
    font-weight: bold;
    color: $color-electric-blue;
  }
}
