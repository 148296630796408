div.columns {
  height: 28rem;
}

div.section {
  margin-bottom: 0;
  width: 90rem;
}

button.filter {
  width: 100%;
  text-align: left;
}

div.info {
  width: 40rem;
}

:global(.resetOverflow) {
  overflow: unset;
}
