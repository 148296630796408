@import "../../../scss/lib.module";

.title {
  font-size: 2rem;
  font-weight: bold;

  :global(.block_title) {
    a {
      color: $body-text-color;
    }
    a:hover {
      text-decoration: none;
    }
  }

  :global(.block_subtitle) {
    font-size: 0.7em;
  }
}
