.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.teamInfoSection {
  display: flex;
  width: 100%;
  max-width: fit-content;
  flex-direction: column;
  gap: 8px;
}

.teamActionsSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: end;
  gap: 1rem;
  align-items: center;
  padding-bottom: 24px;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 8px;
  align-items: end;
}
