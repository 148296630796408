@import "../../../scss/lib.module";

.title {
  font-size: 2rem;
  font-weight: bold;
  :global(.block_title) {
    a {
      color: $body-text-color;
    }
    a:hover {
      text-decoration: none;
    }
  }
  :global(.block_subtitle) {
    font-size: 0.7em;
  }
}

.avatar {
  width: 4.4rem;
  height: 4.4rem;
}


.summary {
  padding: 2rem 1.2rem;
}
