@import "../../../scss/lib.module";

div.opportunity {
  align-items: center;
  border-radius: 4px;
  border: dashed 1px $color-light-gray;
  display: inline-flex;
  outline: none;
  padding: 5px;
  width: 100%;
  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 0;
    overflow: hidden;
  }
  button {
    justify-content: initial;
    width: 100%;
    &:disabled {
      opacity: 0.4;
      user-select: none;
      &:hover {
        color: inherit;
      }
    }
  }

  :global(.menu-title) {
    text-align: left;
  }

  svg {
    margin-right: 5px;
  }
}

.opportunity + .opportunity {
  margin-top: 0.5rem;
}
