@import "../../scss/lib.module";

.tabs {
  background: $color-white;
  flex: 0 0 auto;
}

.summary {
  font-size: 1.8rem;
}

.aside {
  padding: 3.2rem 4rem;
}

.details {
  padding: 0 4rem 5rem;
}
