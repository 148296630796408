@import "../../scss/lib.module";

.tagWrapper {
  border-radius: $input-button-border-radius;
  background: transparent;
  border: 1px solid transparent;
  align-items: center;
  padding: $button-padding;
  text-align: center;
}

.active {
  color: $tag-active-color;
  background-color: $tag-active-background-color;
  font-family: "Open Sans";
  font-weight: 700;
  &:focus {
    outline: none;
  }
}

.danger {
  color: $tag-danger-color;
  background-color: $tag-danger-background-color;
  font-family: "Open Sans";
  font-weight: 700;
  &:focus {
    outline: none;
  }
}

.info {
  color: $tag-info-color;
  background-color: $tag-info-background-color;
  font-family: "Open Sans";
  font-weight: 700;
  &:focus {
    outline: none;
  }
}

.warning {
  color: $tag-warning-color;
  background-color: $tag-warning-background-color;
  font-family: "Open Sans";
  font-weight: 700;
  &:focus {
    outline: none;
  }
}

.large {
  padding: $button-padding-large;
  font-size: 1.8rem;
}

.medium {
  padding: $button-padding-medium;
  font-size: 1.6rem;
}

.small {
  padding: $button-padding-small;
}

.xsmall {
  padding: $button-padding-xsmall;
  font-size: 1.2rem;
}
