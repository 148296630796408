@import "../../scss/lib.module";

.coaster {
  position: absolute;
  top: 100%;
  margin-top: 6px;
  z-index: 4;
  margin-bottom: 3rem;

  background: $menu-background-color;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 1.5rem;
  width: fit-content;
  max-height: 400px;
  overflow-x: auto;

  &:global(.right) {
    right: 0;
  }
  &:global(.left) {
    left: 0;
  }
}
