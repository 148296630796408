// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ! RESET

/// The next rules are, basically, the reset combined with a few baseline rules that we should expect
/// to have. We should be careful about adding anything here that overrides the defaults too much
/// (rather than just normalizing the defaults). The following rules are an augmented version of
/// normalize.css.

html {
  line-height: 1.4;
  -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS
}

img {
  // Remove the border on images inside links in IE 10.
  border-style: none;

  // Get rid of gap under images by making them display: inline-block; by default
  display: inline-block;
  vertical-align: middle;

  // Grid defaults to get images and embeds to work properly
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

table {
  border-collapse: collapse;
}

// Make sure textarea takes on height automatically
textarea {
  height: auto;
  min-height: 50px;
  overflow: auto; /// Remove the default vertical scrollbar in IE 10+.
}

// Make select elements are 100% width by default
select {
  box-sizing: border-box;
  width: 100%;
}

pre,
code {
  overflow: auto; // Prevent text overflow on pre
  // stylelint-disable-next-line
  font-family: monospace, monospace; // Correct the inheritance and scaling of font size in all browsers.
  font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

a {
  background-color: transparent; // Remove the gray background on active links in IE 10.
}

b,
strong {
  font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

// Make reset inherit font-family instead of settings sans-serif
// Change the font styles in all browsers
// Remove the margin in Firefox and Safari
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
}

button,
input {
  overflow: visible; // Show the overflow in Edge.
}

button,
select {
  text-transform: none; // Remove the inheritance of text transform in Firefox.
}

/// Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/// Correct the padding in Firefox.
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

progress {
  vertical-align: baseline; /// Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

/// Add the correct box sizing in IE 10.
/// Remove the padding in IE 10.
[type="checkbox"],
[type="radio"] {
  /* 1 */
  padding: 0;
}

/// Correct the inability to style clickable types in iOS and Safari.
/// Change font properties to `inherit` in Safari.
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
